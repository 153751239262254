import { identifierModuleUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';

@Injectable()
export class Api {

  public baseUrl; // api基本路径

  constructor(
    private http: HttpService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  // #region auth
  /**
   * 用户权限认证
   */
  getAuth() {
    return this.http.get(`/sys/user/auth`);
  }

  // #endregion

  // #region 日程
  /**
   * 日程列表
   */
  getScheduleList(data) {
    return this.http.post(`/user/oaEvent/list`, data);
  }

  /**
   * 添加日程
   */
  addSchedule(data) {
    return this.http.post(`/user/oaEvent/add`, data);
  }

  // #endregion

  // #region 地区产品
  /**
   * 新增地区产品类别
   */
  addAreaCategory(data) {
    // return this.http.post(`/sys/baseAreaCategory/add`, data);
    return this.http.post(`/sys/baseAreaCategory/saveInfo`, data);
  }

  /**
   * 更新地区产品类别
   */
  updateAreaCategory(data) {
    return this.http.post(`/sys/baseAreaCategory/update`, data);
  }
  /**
   * 查询产品的服务
   */
  getByAreaCategoryId(areaCateforyId) {
    return this.http.post(`/sysBaseAreaCategoryType/getByAreaCategoryId/${areaCateforyId}`);
  }

  /**
   * 批量新增产品属性型号
   */
  addAreaProperty(data) {
    return this.http.post(`/sys/baseAreaPropertyModel/batchAdd`, data);
  }

  /**
   * 批量更新产品属性型号
   */
  updateAreaProperty(data) {
    return this.http.post(`/sys/baseAreaPropertyModel/batchUpdate`, data);
  }

  /**
   * 批量更新产品属性
   * @param data
   */
  updateBatchProperty(data) {
    return this.http.post('/sys/baseAreaProperty/saveOrUpdateBatch', data);
  }

  /**
   * 批量更新产品详情
   * @param data
   */
  updateParentCode(data) {
    return this.http.post('/sys/baseAreaProperty/updateParentCode', data);
  }

  /**
   * 批量删除产品属性型号
   */
  deleteAreaProperty(data) {
    return this.http.postParams(`/sys/baseAreaPropertyModel/batchDel`, data);
  }

  /**
   * 修改新添加的产品属性
   */
  addbaseAreaProperty(data) {
    return this.http.post(`/sys/baseAreaProperty/add`, data);
  }

  /**
   * 新添加产品属性
   */
  addAllAreaProperty(data) {
    return this.http.post(`/sys/baseAreaProperty/addAll`, data);
  }

  /**
   * 多选删除产品属性
   */
  deletebaseAreaProperty(data) {
    return this.http.postParams(`/sys/baseAreaProperty/del`, data);
  }

  // /**
  //  * 根据地区产品查询勾选属性
  //  */
  // getProductOwnedAttributes(data) {
  //   return this.http.get(`/sys/baseAreaProperty/getProductOwnedAttributes`, data);
  // }
  /**
   * 根据地区产品查询勾选属性
   */
  getProductAttributesList(data) {
    return this.http.get(`/sys/baseAreaProperty/getProductAttributesList`, data);
  }

  /**
   * 根据产品查询存在属性
   */
  queryingAttributesByProduct(data) {
    return this.http.get(`/sys/baseAreaProperty/queryingAttributesByProduct`, data);
  }

  /**
   * 获取地区产品型号
   */
  getProductModels(data) {
    return this.http.get(`/sys/baseAreaPropertyModel/getProductModels`, data);
  }

  /**
   * 根据产品型号查询对应价格
   */
  getProductPriceByModel(data) {
    return this.http.get(`/sys/baseAreaPropertyModel/getProductPriceByModel`, data);
  }

  /**
   * 添加产品提成
   */
  addCommission(data) {
    return this.http.post(`/sys/orderCommission/add`, data);
  }

  /**
   * 修改产品提成
   */
  updateCommission(data) {
    return this.http.post(`/sys/orderCommission/update`, data);
  }

  /**
   * 根据地区产品Id，获取产品提成配置
   */
  getCommission(data) {
    return this.http.postParams(`/sys/orderCommission/getCommissionByProductId`, data);
  }

  /**
   * 删除产品属性
   */
  delProduct(data) {
    return this.http.get(`/sys/baseAreaProperty/del`, data);
  }

  /**
   * 根据地区产品Id，获取产品提成配置
   */
  addAllProduct(data) {
    return this.http.post(`/sys/baseAreaProperty/addAll`, data);
  }

  /**
   * 修改产品属性
   */
  updateProduct(data) {
    return this.http.post(`/sys/baseAreaProperty/update`, data);
  }

  // #endregion

  /************ 用户 **************/
  // 用户退出
  logOut() {
    return this.http.get(`/sys/user/logout`);
  }

  // 获取用户列表
  getUserList() {
    return this.http.get(`/user/getUserList`);
  }
  // 工具公司名称获取用户列表
  getUserListByCompanyId() {
    return this.http.get(`/user/getUserListByCompanyId`);
  }

  // 根据用户部门，获取部门用户列表
  getUserListByDept() {
    return this.http.get(`/user/getUserListByDept`);
  }

  // 根据部门Id，获取部门用户列表
  getUserListByDeptId(data) {
    return this.http.get(`/user/getUserListByDeptId`, data);
  }

  // 获取用户信息
  getUserInfo() {
    return this.http.get(`/user/getUserInfo`);
  }

  // 修改个人信息
  updateUserInfo(data) {
    return this.http.post(`/user/update`, data);
  }

  // 修改密码
  changePassword(data) {
    return this.http.postParams(`/user/changePassword`, data);
  }

  // 修改附件文件
  updateAttachment() {
    return `/sys/attachment/update`;
  }

  /**
   * @description 生成绑定微信二维码
   * 绑定类型 0客户 1用户
   */
  getqrCodeUrl(data) {
    return this.http.get(`/customer/bsWechatLink/getBindQrUrl`, data);
  }

  // 获取验证码
  getCode(uuid) {
    return `${this.baseUrl}/sys/user/getVerificationCode?uuid=${uuid}`;
  }

  // 获取公海列表
  getPubCustomerList(data) {
    return this.http.post(`/customer/publicCustomerResource/getPublicCustomerList`, data);
  }

  // 跨部门查询客户归属 通过手机
  getCustomerByMobile(mobile) {
    return this.http.post(`/customer/customerInfo/getCustomerByMobile/${mobile}`);
  }

  // 跨部门查询客户归属 通过公司
  getCustomerByCompanyName(companyName) {
    return this.http.post(`/customer/customerInfo/getCustomerByCompanyName/${companyName}`);
  }

  // 获取公海列表透视列表
  getPubCustomerListAll(data) {
    return this.http.post(`/customer/publicCustomerResource/getPublicCustomerListAll`, data);
  }

  // 添加教程/修改教程
  addProKnowledge(data) {
    return this.http.post(`/sys/course/addProKnowledge`, data);
  }

  // 添加教程/修改教程
  getOutCourseList(data) {
    return this.http.post(`/sys/course/getOutCourseList`, data);
  }

  /**
   *
   * @param data 添加创业指南
   */
  addBusinessGuide(data) {
    return this.http.post(`/sys/course/addBusinessGuide`, data);
  }

  // 获取教程最新发布列表
  getProCourseList(data) {
    return this.http.post(`/sys/course/getProCourseList`, data);
  }

  // 获取带权限的类别
  coursePerClassification() {
    return this.http.get(`/system/sysCoursePermissions/coursePerClassification`);
  }

  // 获取教程最新发布列表
  getPermissions(data) {
    return this.http.get(`/system/sysCoursePermissions/get`, data);
  }

  // 获取教程热门推荐列表
  getHotProCourseList(data) {
    return this.http.post(`/sys/course/getHotProCourseList`, data);
  }

  /**
   * 教程权限
   * @param data
   */
  addBatch(data) {
    return this.http.post(`/system/sysCoursePermissions/addBatch`, data);
  }

  /**
   * 获取教程权限人员
   * @param data
   */
  queryCourseUserList(data) {
    return this.http.post(`/sys/user/queryCourseUserList`, data);
  }


  // 获取教程最新发布列表
  getCourseDetails(data) {
    return this.http.get(`/sys/course/getCourse`, data);
  }


  // 批量领取公海资源
  getPubCustomerSources(data) {
    return this.http.postParams(`/customer/publicCustomerResource/collectPubResources`, data);
  }

  // 获取我的客户列表
  getMyCustomerList(data) {
    return this.http.post(`/customer/customerInfo/getMyCustomerList`, data);
  }

  // 获取公司列表
  getMycompanyList(data) {
    return this.http.post(`/customer/bsCompany/companyList`, data);
  }

  /**
   *
   * @param data 导入公司
   */
  importBsCompany(data) {
    // console.log(this.http.upload)
    return this.http.upload(data, `/customer/bsCompany/import`);
  }

  // 获取联系人
  getCompanyCustomerList(data) {
    return this.http.post(`/customer/bsCompany/companyCustomerList/${data.companyId}`);
  }

  // 获取转介绍人
  getReferralUser(data) {
    return this.http.postParams(`/customer/customerInfo/getReferralUser`, data);
  }

  /**
   * 客户列表
   */
  getCustomerList(data) {
    return this.http.post(`/customer/customerInfo/getCustomerList`, data);
  }

  /**
   *
   *客户评价列表
   */
  getCustomerReviewList(data) {
    return this.http.post(`/customer/bsReview/list`, data);
  }

  /**
   * 根据parentCode获取数据字典
   */
  getSysParameters(data) {
    return this.http.get(`/sys/parameters/getSysParameters`, data);
  }

  // 获取客户详情
  getCustomerDetail(data) {
    return this.http.get(`/customer/customerInfo/getCustomer`, data);
  }

  // 用户-公司信息
  getCompanyList(data) {
    return this.http.get(`/customer/bsCompany/getCompanyByCoustomerId`, data);
  }

  // 获取续费标记
  findRenewFlag(data) {
    return this.http.post(`/customer/orderProduct/findRenewFlag`, data);
  }

  // 根据用户ID，查询客户订单
  getOrderListById(data) {
    return this.http.postParams(`/customer/order/orderListById`, data);
  }

  // 根据用户ID，查询订单
  getOrderOrder(data) {
    return this.http.postParams(`/customer/order/order`, data);
  }

  // 查询客户跟进记录
  getCustomerRecord(data) {
    return this.http.post(`/customer/customerJob/select`, data);
  }

  // 添加客户跟进记录
  addCustomerRecord(data) {
    return this.http.post(`/customer/customerJob/add`, data);
  }

  // 修改客户跟进人
  updateCustomerUserid(data) {
    return this.http.post(`/customer/customerJob/update/userid`, data);
  }

  // 查询公司跟进记录
  getCustomerJobRecord(data) {
    return this.http.post(`/bsCompanyJobRecord/companyJobRecordList`, data);
  }
  // 添加公司跟进记录
  addCustomerJobRecord(data) {
    return this.http.post(`/bsCompanyJobRecord/saveBsCompanyJobRecord`, data);
  }

  /**
   *
   * @param data 添加联合跟进人
   */
  addJobJoint(data) {
    return this.http.post(`/customer/customerJob/addJobJoint`, data);
  }

  // 获取联合签单人列表
  relevanceUser(orderId) {
    return this.http.post(`/bsOrderRelevanceUser/getUser/${orderId}`);
  }
  // 保存联合签单人列表
  saveRelevanceUser(data) {
    return this.http.post(`/bsOrderRelevanceUser/saveList`, data);
  }

  // 获取公司信息
  getCompanyDetail(data) {
    return this.http.postParams(`/customer/bsCompany/getBsCompany`, data);
  }

  // 添加公司基本信息
  addCompanyInfo(data) {
    return this.http.post(`/customer/bsCompany/add`, data);
  }

  /**
   * 查看是否有公司存在
   */
  findByCompanyName(data) {
    return this.http.post(`/customer/bsCompany/findByCompanyName?name=${data.name}`);
  }


  /**
   * 获取公司编辑详情
   */
  getCompanyDetails(data) {
    return this.http.get(`/customer/bsCompany/getCompanyDetail`, data);
  }

  /**
   * 修改公司信息
   */
  updateCompany(data) {
    return this.http.post(`/customer/bsCompany/update`, data);
  }

  /**
   * 删除公司客户关联信息
   */
  deleteCompanyCustomer(data) {
    return this.http.postParams(`/customer/bsCompany/deleteCompanyCustomer`, data);
  }

  // 查询标签列表
  getLabelList(data) {
    return this.http.get(`/customer/customerTagRecord/list`, data);
  }

  // 获取客户标签
  getUserLabel(data) {
    return this.http.postParams(`/customer/customerTagRecord/getCustomerTag`, data);
  }

  // 给客户添加标签
  addUserLabel(data) {
    return this.http.post(`/customer/customerTagRecord/addCustomerTag`, data);
  }

  // 获取公司标签
  getCompanyLabel(data) {
    return this.http.postParams(`/sysCompanyTagRecord/getCompanyTag`, data);
  }

  // 获取资质
  getSysParametersList(data) {
    return this.http.get(`/sys/parameters/getSysParametersList/${data.url}`);
  }
  // 获取子资质
  getSysParametersChileList(data) {
    return this.http.get(`/sys/parameters/getSysParametersChileList/${data.id}`);
  }

  // 查询资质名称
  getSysParametersNameList(data) {
    return this.http.post(`/sys/parameters/getSysParametersNameList`, data);
  }

  // 公司资质列表数据
  bsCompanyQualification(data) {
    return this.http.get(`/bsCompanyQualification/listData`, data);
  }
  // 给客户添加资质
  saveBatchBsCompanyQualification(data) {
    return this.http.post(`/bsCompanyQualification/saveBatchBsCompanyQualification`, data);
  }

  // 给客户添加标签
  saveSysCompanyTagRecord(data) {
    return this.http.post(`/sysCompanyTagRecord/saveSysCompanyTagRecord`, data);
  }

  // 新增客户收货地址
  addCustomerAddress(data) {
    return this.http.post(`/customer/bsCustomerAddress/add`, data);
  }

  // 删除客户收货地址
  deleteCustomerAddress(data) {
    return this.http.postParams(`/customer/bsCustomerAddress/delete`, data);
  }

  // 获取客户收货地址
  getCustomerAddress(data) {
    return this.http.get(`/customer/bsCustomerAddress/get`, data);
  }

  // 设置默认收货地址
  setDefaultAddress(data) {
    return this.http.postParams(`/customer/bsCustomerAddress/setDefault`, data);
  }

  // 增加系统标签
  addLabel(data) {
    return this.http.post(`/sys/sysLabel/add`, data);
  }


  // 删除标签
  deleteLabel(data) {
    return this.http.postParams(`/sys/sysLabel/del`, data);
  }

  // 获取所有菜单
  getMenuList() {
    return this.http.get(`/sys/menu/list`);
  }

  // 获取所有菜单2
  getMenuListData() {
    return this.http.post(`/sys/menu/listData`);
  }


  // 新增菜单
  addMenu(data) {
    return this.http.post(`/sys/menu/add`, data);
  }

  // 更新菜单
  updateMenu(data) {
    return this.http.postParams(`/sys/menu/update`, data);
  }

  // 获取菜单
  getMenu(data) {
    return this.http.get(`/sys/menu/get`, data);
  }

  // 删除菜单
  delMenu(data) {
    return this.http.get(`/sys/menu/del`, data);
  }

  // 获取所有菜单权限
  getMenuPermissionList(data) {
    return this.http.postParams(`/sys/menuPermission/getMenuPermissionList`, data);
  }

  // 获取所有菜单权限
  getMenuPermissionListByParentId(data) {
    return this.http.postParams(`/sys/menuPermission/getMenuPermissionListByParentId`, data);
  }

  // 删除菜单权限
  delMenuPermission(data) {
    return this.http.postParams(`/sys/menuPermission/del`, data);
  }

  // 获取系统权限列表
  getsysPermissionList(data) {
    return this.http.post(`/sys/permission/list`, data);
  }

  // 新增菜单权限
  addSysPermissio(data) {
    return this.http.postParams(`/sys/menuPermission/add`, data);
  }

  // 将客户释放到公海
  backCustomerSources(data) {
    return this.http.postParams(`/customer/publicCustomerResource/addCustomerResource`, data);
  }

  // web端呼叫客户
  webRequestBreathe(data) {
    return this.http.get(`/sys/breathe/webRequestBreathe`, data);
  }

  // web端呼叫客户
  checkingUser() {
    return this.http.get(`/sys/breathe/checkingUser`);
  }

  // 获取省市信息
  getCityList(data) {
    return this.http.post(`/sys/region/getLevel`, data);
  }
  // #region 
  // （无角色限制）获取省市级联动数据
  getProvinciDataByNoRole() {
    return this.http.get(`/sys/region/getProvincialLeagueDataByNoRole`);
  }
  // #region 地区信息
  // 获取省市信息
  getDimensionAreaData() {
    return this.http.get(`/sys/region/getDimensionAreaData`);
  }

  // #endregion

  // 新增客户信息
  addCustomer(data) {
    return this.http.post(`/customer/customerInfo/add`, data);
  }
  // 渠道商新增客户信息
  addCustomerCus(data) {
    return this.http.post(`/customer/customerInfo/addCustomer`, data);
  }

  // 修改客户信息
  updateCustomer(data) {
    return this.http.post(`/customer/customerInfo/update`, data);
  }

  // 获取线索池列表
  getThreadList(data) {
    return this.http.post(`/customer/publicCustomerResource/getCollectInitResources`, data);
  }

  // 批量领取线索池资源
  getThreadSources(data) {
    return this.http.post(`/customer/publicCustomerResource/collectInitResources`, data);
  }

  // Excel 批量导入线索池
  importThread() {
    return `/customer/publicCustomerResource/addByExcel`;
  }

  /**
   * 新增线索
   */
  addInit(data) {
    return this.http.post(`/customer/publicCustomerResource/addInit`, data);
  }

  /**
  * 新增线索-指定
  */
  appointInit(data) {
    return this.http.post(`/customer/publicCustomerResource/appointInit`, data);
  }


  /**
   * 新增线索-惠用心
   */
  addHuiInit(data) {
    return this.http.post(`/customer/publicCustomerResource/addHuiHeatClues`, data);
  }

  /****
   * 修改线索来源
   */
  editSource(data) {
    return this.http.postParams(`/customer/publicCustomerResource/editSource`, data);
  }
  /************ 订单 **************/

  // 查询我的订单列表
  getMyOrderList(data) {
    return this.http.post(`/customer/order/list`, data);
  }
  // 查询联合签单人订单列表
  getMyOrderListJoin(data) {
    return this.http.post(`/bsOrderRelevanceUser/orderList`, data);
  }
  // 新增工单 - 查询我的订单列表
  getMyOrderTaskList(data) {
    return this.http.post(`/customer/order/task/list`, data);
  }
  // 查询订单详情
  getOrderDetail(data) {
    return this.http.postParams(`/customer/order/orderInfo`, data);
  }

  // 根据订单，查询订单产品
  getOrderProduct(data) {
    return this.http.postParams(`/customer/orderProduct/getOrderProduct`, data);
  }

  // 根据订单ID查询退款记录
  getRefundRecord(data) {
    return this.http.postParams(`/sys/orderRefunds/refundRecord`, data);
  }
  // 修改付款公司
  payUpdateById(data) {
    return this.http.post(`/customer/payRecord/updateById`, data);
  }
  // 修改产品类型
  updateProductType(data) {
    return this.http.post(`/customer/orderProduct/updateProductType`, data);
  }
  // 修改产品单位
  updateProductNature(data) {
    return this.http.post(`/customer/orderProduct/updateProductNature`, data);
  }
  // 修改产品成本
  updateProductCost(data) {
    return this.http.post(`/customer/orderProduct/updateProductCost`, data);
  }

  // 续费订单
  getRenewalOrderList(data) {
    return this.http.post(`/customer/customerInfo/getRenewalCustomerVOs`, data);
  }

  // 订单审核列表
  getPayVerifyList(data) {
    return this.http.post(`/customer/offlinePay/payVerifyList`, data);
  }


  // 支付审核
  payVerify(data) {
    return this.http.post(`/customer/offlinePay/payVerify`, data);
  }

  // 提成审核
  commissionVerify(data) {
    return this.http.postParams(`/sys/commissionRecord/commissionVerify`, data);
  }

  // 查询订单退款列表
  getOrderRefundsList(data) {
    return this.http.post(`/sys/orderRefunds/list`, data);
  }

  // 查询订单退款列表
  orderRefundsVerify(data) {
    return this.http.post(`/sys/orderRefunds/verify`, data);
  }

  // 申请退款
  addOrderRefundsVerify(data) {
    return this.http.post(`/sys/orderRefunds/add`, data);
  }

  // 判断线上退款 是否超过1年
  payOverYear(orderId) {
    return this.http.post(`/customer/payRecord/overYear/${orderId}`);
  }

  // 申请退款 直接到预收款
  addOrderRefundsVerifyYsk(data) {
    return this.http.post(`/sys/orderRefunds/addYsk`, data);
  }

  // 退款详情
  /**
   * @description 退款详情
   */
  userOrderRefundsDeails(id) {
    return this.http.post(`/sys/orderRefunds/detail/${id}`)
  }

  /**
   * @description 查询订单任务列表
   */
  getTaskList(data) {
    return this.http.post(`/customer/orderTask/list`, data);
  }

  // 查询客户公司列表
  myCompanyList(data) {
    return this.http.post(`/customer/bsCompany/myCustomerCompanyList`, data);
  }


  // 根据客户ID判断是否可以下推荐订单
  introduceFlag(customerId) {
    return this.http.post(`/customer/order/introduceFlag/${customerId}`)
  }

  /**
   * @description 上传支付凭证
   */
  uploadRecord() {
    return `/customer/payRecord/savePayFile`;
  }

  /**
   * @description 上传开票凭证
   */
  uploadInvoice() {
    return `/sys/invoice/saveInvoiceFile`;
  }
  /**
     * @description 上传开票凭证 新
     */
  uploadInvoices() {
    return `/sys/invoice/saveInvoiceTrustFile`;
  }
  /**
   * @description 根据订单ID，查询已支付金额
   */
  getPayAmount(data) {
    return this.http.postParams(`/customer/order/findPayAmount`, data);
  }

  /**
   * @description 添加订单
   */
  addOrder(data) {
    return this.http.post(`/customer/order/add`, data);
  }

  /**
   * @description 修改订单
   */
  updateOrder(data) {
    return this.http.post(`/customer/order/update`, data);
  }
  // 删除产品
  /**
   * @description 删除产品
   */
  delOrderProduct(data) {
    return this.http.post(`/customer/order/delProduct/` + data.id);
  }

  /**
   * @description 删除产品更新金额
   */
  updateOrderPrice(data) {
    return this.http.post(`/customer/order/updateOrderPrice`, data);
  }

  /**
   * @description 查出用户公司下所有的部门
   */
  findCompanyDept() {
    return this.http.post(`/sys/office/findCompanyDept`);
  }
  /**
   * @description 查出用户公司下所有的部门 树形结构
   */
  findCompanyDeptTree() {
    return this.http.post(`/sys/office/findCompanyDeptTree`);
  }

  /**
   * @description 查询任务提成配置列表
   */
  getOrderTypeList() {
    return this.http.post(`/sys/taskCommisssion/list`);
  }

  getTaskTypeList(data) {
    return this.http.post(`/sys/taskCommisssion/list`, data);
  }

  /**
   * @description 选择公司-公司信息
   */
  getCompanySelectByCusId(data) {
    return this.http.get(`/customer/bsCompany/getCompanySelectByCusId`, data);
  }

  /**
   * @description 选择公司-公司信息
   */
  getCompanySelect() {
    return this.http.get(`/customer/bsCompany/getCompanySelect`);
  }


  /**
   * @description 添加订单任务
   */
  addOrderTask(data) {
    return this.http.post(`/customer/orderTask/add`, data);
  }

  /**
   * @description 查询支付方式  在用的 
   */
  getPayMode() {
    return this.http.post(`/sys/payments-code/get`);
  }

  /**
   * @description 查询支付方式 所有的
   */
  getPayModeAllList() {
    return this.http.post(`/sys/payments-code/listView`);
  }

  /**
   * @description 查询收款公司
   */
  getReceivingCompany(data) {
    return this.http.get(`/sys/signedEnterprise/getCurrentSignedEnterprise`, data);
  }

  /**
   * @description 添加线下支付记录
   */
  offlinePayment(data) {
    return this.http.post(`/customer/offlinePay/add`, data);
  }

  /**
   * @description 根据订单ID，查询支付记录
   */
  getPaymentList(data) {
    return this.http.postParams(`/customer/payRecord/payRecordList`, data);
  }

  // 订单发票列表
  getInvoiceList(data) {
    return this.http.post(`/sys/invoice/invoiceList`, data);
  }
  // 我的发票列表
  getMyInvoiceList(data) {
    return this.http.post(`/sys/invoice/myInvoiceList`, data);
  }


  // 订单发票审核
  verifyInvoice(data) {
    return this.http.post(`/sys/invoice/verifyInvoice`, data);
  }
  // 订单发票红冲
  redDashedInvoice(data) {
    return this.http.post(`/sys/invoice/redDashed`, data);
  }

  // 修改合同状态
  updateContractStatus(data) {
    return this.http.post(`/customer/order/updateContractStatus`, data);
  }

  // 根据发票Id，查询订单发票详情
  getInvoiceDetail(data) {
    return this.http.postParams(`/sys/invoice/orderInvoiceInfo`, data);
  }

  // 提成审核列表
  getCommissionList(data) {
    return this.http.post(`/sys/commissionRecord/list`, data);
  }

  //提成审核详情列表
  commissionVerifyList(data) {
    return this.http.post(`/sys/commissionRecord/commissionVerifyList`, data);
  }

  /************ 首页 **************/

  // 数据面板-首页
  getPanelDataVO(data) {
    return this.http.get(`/sys/achievement/getPanelDataVO`, data);
  }

  // 获取漏斗数据
  getSellingVulnerabilities(data) {
    return this.http.get(`/sys/achievement/getSellingVulnerabilities`, data);
  }

  // 获取ROI数据
  getRoi(data) {
    return this.http.get(`/sys/achievement/getRoi`, data);
  }

  // 获取目标完成率数据
  getTargetCompletion(data) {
    return this.http.get(`/sys/achievement/getTargetCompletion`, data);
  }

  // 获取记账报税排行榜
  getAgencyLeague(data) {
    return this.http.get(`/sys/achievement/getAgencyLeague`, data);
  }


  // 数据面板-销售漏斗
  getResourcePool(data) {
    return this.http.get(`/sys/achievement/getSellingVulnerabilities`, data);
  }

  // 任务数量统计
  getTaskAccount() {
    return this.http.post(`/customer/orderTask/taskAccount`);
  }

  // 总签约单数
  getOrderCountList(data) {
    return this.http.post(`/customer/customerInfo/getOrderCountList`, data);
  }


  // 导航栏列表
  getLinks(data) {
    return this.http.post(`/user/navigationBar/list`, data);
  }

  // 用户新增导航栏
  addLink(data) {
    return this.http.post(`/user/navigationBar/add`, data);
  }

  // 用户更新导航栏
  updateLink(data) {
    return this.http.post(`/user/navigationBar/update`, data);
  }

  // 用户删除导航栏
  deleteLink(data) {
    return this.http.get(`/user/navigationBar/del`, data);
  }

  /**
   * @description 联动查产品类别
   */
  getLinkageCategory(data) {
    return this.http.get(`/sys/baseCategory/getLinkageCategory`, data);
  }

  /**
   * @description 获取地区产品
   */
  // getRegionalProduct(data) {
  //   return this.http.post(`/sys/baseAreaCategory/getRegionalProduct`, data);
  // }

  /**
   * @description 根据类别查询地区产品
   */
  getFollowUpByCategory(data) {
    return this.http.get(`/sys/baseAreaCategory/getFollowUpByCategory`, data);
  }

  // 短信任务列表
  getTaskDataList(data) {
    return this.http.post(`/sys/qcloudMsg/list`, data);
  }

  // 短信任务列表查看
  getQcloudMsgLogDateils(data) {
    return this.http.get(`/sys/qcloudMsgLog/logItems`, data);
  }

  // 短信模板
  getTemplateDataList(data) {
    return this.http.post(`/sys/qcloudMsgConfig/list`, data);
  }

  // 新增短信模板
  // /sys/qcloudMsgConfig/smsManageSave
  getTemplateSmsManageSave(data) {
    return this.http.post(`/sys/qcloudMsgConfig/smsManageSave`, data);
  }

  // 新增短信模板
  // /sys/qcloudMsgConfig/deletesmsManage
  deletesmsManage(data) {
    return this.http.post(`/sys/qcloudMsgConfig/deletesmsManage`, data);
  }

  // 腾讯云同步模板
  getTemplateSynchronization(data) {
    return this.http.get(`/sys/qcloudMsgConfig/synchroData`, data);
  }

  // 短信签名
  getSigntureDataList(data) {
    return this.http.post(`/sys/qcloudMsgsign/list`, data);
  }

  // 腾讯云同步签名
  getSigntureSynchronization(data) {
    return this.http.get(`/sys/qcloudMsgsign/synchroData`, data);
  }

  // 新增短信签名
  getSignManageSave(data) {
    return this.http.post(`/sys/qcloudMsgsign/signManageSave`, data);
  }

  // 获取公司短信配置
  getCompanyNoteAllocation(data) {
    return this.http.get(`/sys/qcloudConfig/get`, data);
  }

  // 发送短信
  UserSendNoteList(data) {
    return this.http.post(`/sys/qcloudMsg/singleSendSms`, data);
  }


  // 用户列表
  getUserDataListPage(data) {
    return this.http.post(`/customer/customerInfo/listPage`, data);
  }

  // 公告
  // 公告列表
  getMessageGetList(data) {
    return this.http.post(`/sys/message/getSysList`, data);
  }

  // 发布公告
  getSysMessageAdd(data) {
    return this.http.post(`/sys/message/add`, data);
  }

  // 删除公告
  delSysMessageAdd(data) {
    return this.http.get(`/sys/message/del`, data);
  }

  // 修改公告
  modificationSysMessage(data) {
    return this.http.post(`/sys/message/update`, data);
  }

  // 系统角色管理
  // 角色列表
  getRoleList(companyId) {
    return this.http.get(`/sys/role/list/${companyId}`);
  }

  // 新增角色
  addRoleList(data) {
    return this.http.post(`/sys/role/add`, data);
  }

  // 删除角色
  deleteRoleList(data) {
    return this.http.get(`/sys/role/del`, data);
  }

  // 修改角色
  updateRoleList(data) {
    return this.http.post(`/sys/role/update`, data);
  }

  // 角色员工列表
  getRoleUserList(data) {
    return this.http.post(`/sys/userRole/getRoleUser`, data);
  }
  // 获取用户角色信息
  getUserRoles(userId) {
    return this.http.post(`/sys/userRole/getUserRoles/${userId}`);
  }
  // 更新用户权限
  addUserRoles(data) {
    return this.http.post(`/sys/userRole/addUserRoles`, data);
  }

  // 关联员工列表
  getRoleUserListRelevancy(data) {
    ///user/getUserListByDeptId
    return this.http.get(`/user/getUserListByDeptId`, data);
  }

  // 角色关联员工
  getRoleRelevancyUserList(data) {
    return this.http.post(`/sys/userRole/batchAdd`, data);
  }

  // 删除角色员工
  getDeleteRoleUser(data) {
    return this.http.get(`/sys/userRole/del`, data);
  }

  // 角色权限菜单列表
  getUserRoleMenuPermissionList(data) {
    return this.http.get(`/sys/role/menuPermission/getRoleList`, data);
  }

  // 角色菜单权限列表
  getUserRoleMenuPermissionListNetx(data) {
    return this.http.get(`/sys/role/menuPermission/getMenuPermissionList`, data);
  }

  // 角色菜单权限列表
  getMenuPermissionListByRole(data) {
    return this.http.get(`/sys/role/menuPermission/getMenuPermissionListByRole`, data);
  }

  // // 新增角色菜单
  // addMenuPermission(data) {
  //   return this.http.postParams(`/sys/role/menuPermission/add/menu`, data);
  // }
  // // 删除角色菜单
  // deleteMenuPermission(data) {
  //   return this.http.postParams(`/sys/role/menuPermission/del/menu`, data);
  // }
  // 新增角色菜单权限
  addAttrPermission(data) {
    return this.http.postParams(`/sys/role/menuPermission/add/permission`, data);
  }

  // 删除角色菜单权限
  deleteAttrPermission(data) {
    return this.http.postParams(`/sys/role/menuPermission/del`, data);
  }

  // 获取未读消息个数
  getMessageNumber() {
    return this.http.get(`/sys/message/getUnreadMessageNumber`);
  }

  // 用户公告列表
  getUserMessageDataList(data) {
    return this.http.post(`/sys/message/getUserList`, data);
  }

  // 读取消息
  UserReadMessageNubmer(data) {
    return this.http.get(`/sys/message/readMessage`, data);
  }


  /**
   * @description 根据地区产品查属性
   */
  getProductAttr(data) {
    return this.http.get(`/sys/baseAreaProperty/getAreaAttributes`, data);
  }

  /**
   * @description 树形产品类别
   */
  getTreeCategory() {
    return this.http.get(`/sys/baseCategory/getTreeCategory`);
  }

  /**
   * @description 分页产品类别
   */
  getCategoryList(data) {
    return this.http.post(`/sys/baseCategory/list`, data);
  }

  /**
   * @description 获取产品类别树形数据
   */
  getAllParameters(data) {
    return this.http.get(`/sys/parameters/getAllParameters`, data);
  }

  /**
   * @description 新增产品类别
   */
  addBaseCategory(data) {
    return this.http.post(`/sys/baseCategory/add`, data);
  }

  /**
   * @description 更新产品类别
   */
  updateBaseCategory(data) {
    return this.http.post(`/sys/baseCategory/update`, data);
  }

  /**
   * @description 多选删除产品类别
   */
  deleteBaseCategory(data) {
    return this.http.postParams(`/sys/baseCategory/del`, data);
  }

  /**
   * @description 分页产品属性
   */
  getPropertyList(data) {
    return this.http.post(`/sys/baseProperty/list`, data);
  }

  /**
   * @description 获取产品属性字典
   */
  getProductPropList(data) {
    return this.http.get(`/sys/parameters/getPropertiesList`, data);
  }

  /**
   * @description 根据模板新增产品属性
   */
  addTemplateAttr(data) {
    return this.http.postParams(`/sys/baseProperty/addTemplateAttributes`, data);
  }

  /**
   * @description 新增产品属性
   */
  addBaseProperty(data) {
    return this.http.post(`/sys/baseProperty/add`, data);
  }

  /**
   * @description 更新产品属性
   */
  updateBaseProperty(data) {
    return this.http.post(`/sys/baseProperty/update`, data);
  }

  /**
   * @description 多选删除产品属性
   */
  deleteBaseProperty(data) {
    return this.http.postParams(`/sys/baseProperty/del`, data);
  }

  /**
   * @description 多选删除地区产品类别
   */
  deleteBaseAreaCategory(data) {
    return this.http.postParams(`/sys/baseAreaCategory/del`, data);
  }

  /**
   * @description 根据产品ID查属性
   */
  getAttributesByProductId(data) {
    return this.http.get(`/sys/baseProperty/getAttributesByProductId`, data);
  }

  /**
   * @description 地区产品类别列表
   */
  getProductList(data) {
    return this.http.post(`/sys/baseAreaCategory/list`, data);
  }

  /**
   * @description 分页查询系统参数配置
   */
  getDictionaryList(data) {
    return this.http.post(`/sys/parameters/list`, data);
  }

  /**
   * @description 分页查询系统参数配置 2
   */
  getListFirstFloorList(data) {
    return this.http.post(`/sys/parameters/listFirstFloor`, data);
  }

  /**
   * @description 新增系统参数配置
   */
  addDictionary(data) {
    return this.http.post(`/sys/parameters/add`, data);
  }

  /**
   * @description 更新系统参数配置
   */
  editDictionary(data) {
    return this.http.post(`/sys/parameters/update`, data);
  }

  /**
   * @description 多选删除系统参数配置
   */
  deleteDictionary(data) {
    return this.http.postParams(`/sys/parameters/del`, data);
  }

  /**
   * @description 上传附件
   */
  uplaodAttachment() {
    return `/sys/attachment/upload`;
  }

  /**
   * @description 上传合同附件
   */
  uploadContractAttachment() {
    return `/sys/contract/uploadAttachment`;
  }


  uploadInvoiceAttachment() {
    return `/sys/contract/uploadInvoiceAttachment`;
  }

  /**
   * @description 上传并识别营业执照
   */
  bsCompanyDiscernLicense() {
    return `/customer/bsCompany/discern/license`;
  }

  /**
   * @description 根据订单ID,查询合同信息
   */
  getContractDetail(data) {
    return this.http.postParams(`/sys/contract/contractRecord`, data);
  }

  /**
   * @description 根据订单ID,查询合同信息~~~
   */
  getContract(data) {
    return this.http.postParams(`/sys/contract/getAttachmentById`, data);
  }

  /**
   * @description 根据订单ID，查询订单发票
   */
  getInvoicebyOrder(data) {
    return this.http.postParams(`/sys/invoice/list`, data);
  }

  /**
  * @description修改发票附件
  */
  updateInvoiceRecord(data) {
    return this.http.post(`/sys/invoice/updateInvoiceRecord`, data);
  }

  /**
  * @description修改发票附件
  */
  updateInvoice(data) {
    return this.http.post(`/sys/invoice/update`, data);
  }

  deleteInvoice(data) {
    return this.http.post(`/sys/invoice/del?id=` + data);
  }

  /**
   * @description 保存合同
   */
  saveContract(data) {
    return this.http.post(`/sys/contract/saveContract`, data);
  }

  /**
   * @description 保存合同
   */
  editContract(data) {
    return this.http.post(`/sys/contract/modifyAttachment`, data);
  }

  /**
   * @description 根据合同Id，查询合同信息
   */
  findContractData(data) {
    return this.http.postParams(`/sys/contract/findAttachmentById`, data);
  }

  /**
   * @description 公司部门查询
   */
  getDepartment(data) {
    return this.http.post(`/sys/office/queryUserList`, data);
  }

  /**
   * @description 新增公司/部门类别
   */
  addOffice(data) {
    return this.http.post(`/sys/office/add`, data);
  }

  /**
   * @description 修改公司/部门类别
   */
  updateOffice(data) {
    return this.http.post(`/sys/office/update`, data);
  }
  /**
   * @description 修改公司公司负责人员
   */
  updateOfficeMember(data) {
    return this.http.post(`/sys/office/updateCompany`, data);
  }

  /**
   * 删除公司/部门
   */
  deleteOffice(data) {
    return this.http.postParams(`/sys/office/del`, data);
  }

  /**
   * @description 根据用户权限，显示任务指派列表
   */
  getAppointTaskList(data) {
    return this.http.post(`/customer/orderTask/appointTaskList`, data);
  }

  /**
   * @description 指派任务
   */
  assignTask(data) {
    return this.http.post(`/customer/orderTask/appoint`, data);
  }

  /**
   * @description 改变任务状态：2(开启) 4(拒绝)
   */
  changeTaskStatus(data) {
    return this.http.postParams(`/customer/orderTask/comfirm`, data);
  }

  /**
   * @description 根据续费订单,找父订单产品的任务
   */
  getParentTaskList(data) {
    return this.http.postParams(`/customer/orderTask/findProduct`, data);
  }

  /**
   * @description 添加续费订单任务
   */
  addRenewTask(data) {
    return this.http.post(`/customer/orderTask/addRenew`, data);
  }

  /**
   * @description 根据任务Id，查询任务详情
   */
  getTaskDetail(data) {
    return this.http.postParams(`/customer/orderTask/findTaskInfo`, data);
  }

  /**
   * @description 获取任务跟进记录
   */
  getTaskRecordList(data) {
    return this.http.post(`/customer/orderTask/getTaskContinueList`, data);
  }

  /**
   * @description 编辑工单
   */
  editOrderTask(data) {
    return this.http.post(`/customer/orderTask/edit`, data);
  }

  /**
   * @description 渠道商查看任务跟进
   */
  getTaskRecord(data) {
    return this.http.post(`/customer/customerJob/getTaskRecord`, data);
  }

  /**
   * @description 跟进任务
   */
  addTaskRecordList(data) {
    return this.http.post(`/customer/orderTask/continueTask`, data);
  }

  //continueTaskToCustomer
  /**
   * @description 交付记录
   */
  addDeliveryRecordList(data) {
    return this.http.post(`/customer/orderTask/continueTaskToCustomer`, data);
  }

  /**
   * @description 添加子工单
   */
  addChildTask(data) {
    return this.http.post(`/customer/orderTask/addChild`, data);
  }

  /**
   * @description 开启任务
   */
  addCustomerTask(data) {
    return this.http.post(`/customer/orderTask/startTask`, data);
  }

  /**
   * @description 获取手机验证码
   */
  getphoneCode(data) {
    return this.http.postParams(`/customer/bsWechatLink/getIphoneCode`, data);
  }

  /**
   * @description 绑定微信授权信息
   */
  bindWechat(data) {
    return this.http.postParams(`/customer/bsWechatLink/wechatUser`, data);
  }

  /**
   * @description 获取微信信息
   */
  getWechatInfo(data) {
    return this.http.postParams(`/customer/bsWechatLink/getWechat`, data);
  }

  /**
   * @description 生成支付链接二维码，填入需要支付的基本进行：金额等，生成支付订单
   */
  getWechatPayQrcode(data) {
    return this.http.post(`/customer/bsWechatLink/getPayQrUrl`, data);
  }

  /**
   * @description 添加电子订单发票
   */
  addInvoice(data) {
    return this.http.post(`/sys/invoice/addOnlineInvoice`, data);
  }

  /**
   * @description 绑定微信支付用户授权信息，进入支付界面,返回支付信息
   */
  getWechatPayUser(data) {
    return this.http.get(`/customer/bsWechatLink/getWechatPayUser`, data);
  }

  /**
   * @description 微信支付config
   */
  getWxSign(data) {
    return this.http.get(`/customer/bsWechatLink/getWxSign`, data);
  }

  /**
   * @description 支付，调用微信预支付订单
   */
  wxToPay(data) {
    return this.http.get(`/customer/bsWechatLink/pay`, data);
  }

  /**
   * @description 获取合同列表
   */
  getContractList(data) {
    return this.http.post(`/sys/contract/getContractList`, data);
  }
  /**
   * @description 更改合同文件
   */
  updateContractAttachment(data) {
    return this.http.post(`/sys/contract/updateContractAttachment`, data);
  }

  /**
   * @description 根据合同Id，查询上传凭证
   */
  getContractFile(data) {
    return this.http.postParams(`/sys/contract/contractFile`, data);
  }

  /**
   * @description 合同审核
   */
  contractReview(data) {
    // contractReview 旧审核
    return this.http.postParams(`/sys/contract/contractReviewNew`, data);
  }

  /**
   * 日志列表
   */
  getLoggerList(data) {
    return this.http.post(`/sys/log/getSysLog`, data);
  }

  // 删除日志信息
  deleteLogger(data) {
    return this.http.post(`/sys/log/delete`, data);
  }

  // 删除日志信息
  getNewAddCustomerList(data) {
    return this.http.post(`/customer/customerInfo/getNewAddCustomer`, data);
  }

  // 获取用户配置
  getUserConfigList(data) {
    return this.http.get(`/system/sysUserConfig/getUserConfigList`, data);
  }

  // 获取当个用户参数配置
  selectUserConfig(data) {
    return this.http.post(`/system/sysUserConfig/getUserConfigList`, data);
  }

  /**
   * 新增用户配置
   */
  saveUserConfig(data) {
    return this.http.post(`/system/sysUserConfig/addUserConfig`, data);
  }

  /**
   * 删除用户配置
   */
  delUserConfig(data) {
    return this.http.postParams(`/system/sysUserConfig/delUserConfig`, data);
  }

  // 获取当个用户数据维度配置信息
  selectUserDataPer(data) {
    return this.http.get(`/sys/userDataPermission/list`, data);
  }

  // 删除数据维度信息
  deleteUserDataPer(data) {
    return this.http.postParams(`/sys/userDataPermission/delete`, data);
  }

  /**
   * @description 获取公司信息
   */
  getOfficeAttr() {
    return this.http.get(`/sys/office/queryTreeList`);
  }

  /**
 * @description 获取公司信息
 */
  getPerOfficeAttrPost(data?) {
    return this.http.post(`/sys/office/queryPerTreeList`, data);
  }
  /**
   * @description 获取公司信息
   */
  getPerOfficeAttr(data?) {
    if (data) {
      return this.http.get(`/sys/office/queryPerTreeList`, data);
    } else {
      return this.http.get(`/sys/office/queryPerTreeList`);
    }
  }
  /**
   * @description 获取公司信息
   */
  getPerTreeNullList(data) {
    return this.http.get(`/sys/office/queryPerTreeNullList`, data);
  }

  /**
   * @description 分页员工信息-根据公司/部门 查询不带权限的用户
   */
  getUserOfficeList(data) {
    return this.http.post(`/sys/user/queryUserList`, data);
  }

  /**
  * @description 分页员工信息-查自己的公司用户
  */
  queryUserListByCompany(data) {
    return this.http.post(`/sys/user/queryUserListByCompany`, data);
  }

  /**
   * 查询带权限的用户
   * @param data
   */
  queryPerUserList(data) {
    return this.http.post(`/sys/user/queryPerUserList`, data);
  }


  /**
   * 根据部门信息查询员工信息
   */
  getUserByOffice(data) {
    return this.http.post(`/sys/user/queryUsers`, data);
  }

  // 新增用户信息
  addUser(data) {
    return this.http.post(`/sys/user/add`, data);
  }

  // 修改用户信息
  updateUser(data) {
    return this.http.post(`/sys/user/update`, data);
  }

  /**
   * 修改用户状态
   */
  updateUserStatus(data) {
    return this.http.postParams(`/sys/user/updateStatus`, data);
  }

  // 获取用户详情
  getUserDetail(data) {
    return this.http.get(`/sys/user/getUserInfo`, data);
  }

  // 获取数据权限对应类型
  getDataPer(data) {
    return this.http.get(`/sys/office/getDataPer`, data);
  }

  /**
   * 新增用户数据权限
   */
  addUserPer(data) {
    return this.http.post(`/sys/userDataPermission/add`, data);
  }
  // 获取公司或者部门
  getCompanyOrDept(data) {
    return this.http.get(`/sys/office/getCompanyOrDept`, data);
  }

  // 无权限限制查询所有的公司
  getAllOfficeCompanyList() {
    return this.http.get(`/sys/office/getAllOfficeWithOurPermission`);
  }

  // 获取结算发起方与接收方 公司 结算审核
  getCompanys(data) {
    return this.http.get(`/sys/office/getCompanys`, data);
  }

  // 获取部门
  getDept(data) {
    return this.http.get(`/sys/office/getDept`, data);
  }

  // 获取无需公司的部门
  getAuthorityDept(data) {
    return this.http.get(`/sys/office/getAuthorityDept`, data);
  }

  /**
   * 获取部门/个人业绩目标
   */
  getAchievementList(data) {
    return this.http.post(`/sys/achievement/list`, data);
  }

  // 新增业绩目标
  addAchievement(data) {
    return this.http.post(`/sys/achievement/add`, data);
  }

  /**
   * 员工领取资源记录
   */
  getUserConsumptions(data) {
    return this.http.post(`/customer/bsUserConsumption/getUserConsumption`, data);
  }

  /**
   * 某个员工领取详情
   */
  getUserConsumptionList(data) {
    return this.http.post(`/customer/bsUserConsumption/getUserConsumptionList`, data);
  }

  getOrderCommissionConfigList(data) {
    return this.http.post(`/sys/orderCommission/list`, data);
  }

  /**
   * 获取订单提成模板
   */
  getOrderCommissionConfigModelList(data) {
    return this.http.post(`/sys/orderCommission/listModel`, data);
  }

  /**
   * 新增或者修改订单提成模板
   */
  addOrderCommissionConfig(data) {
    return this.http.post(`/sys/orderCommission/addModel`, data);
  }

  /**
   * 删除配置
   */
  deleteOrderCommissionConfig(data) {
    return this.http.post(`/sys/orderCommission/del`, data);
  }

  /**
   * 获取来源信息
   */
  getSourceConfigList(data) {
    return this.http.post(`/customer/sysSourceConfig/get`, data);
  }

  /**
   * 获取来源信息
   */
  getSourceConfigBaseList(data) {
    return this.http.post(`/customer/sysSourceConfig/getBase`, data);
  }

  /**
   * 删除来源配置信息
   * @param data
   */
  delSourceConfig(data) {
    return this.http.postParams(`/customer/sysSourceConfig/delete`, data);
  }

  /**
   * 获取子来源信息
   */
  getSourceConfigChild(data) {
    return this.http.get(`/customer/sysSourceConfig/getChild`, data);
  }

  /**
   * 获取来源信息
   */
  getSourceConfigPageList(data) {
    return this.http.post(`/customer/sysSourceConfig/getPage`, data);
  }

  /**
   * 新增来源
   */
  addSourceConfig(data) {
    return this.http.post(`/customer/sysSourceConfig/add`, data);
  }

  /**
   * 获取微信通知
   */
  getWechatNotice(data) {
    return this.http.get(`/customer/orderTask/weChatNotice`, data);
  }

  getTaskContinueListBySys(data) {
    return this.http.post(`/customer/orderTask/getTaskContinueListBySys`, data);
  }

  /**
   * 根据任务id，获取子任务列表
   */
  getChildTaskList(data) {
    return this.http.postParams(`/customer/orderTask/childList`, data);
  }

  /**
   * 根据支付Id，查询支付附件
   */
  getPayFile(data) {
    return this.http.postParams(`/customer/payRecord/payFile`, data);
  }


  /**
   * 根据订单Id，查询支付附件
   */
  payRecordListByOrderId(data) {
    return this.http.postParams(`/customer/payRecord/payRecordListByOrderId`, data);
  }

  /**
   * 查出数据维度的公司或部门
   */
  getOfficeDataDimension(data) {
    return this.http.get(`/sys/office/getOfficeDataDimension`, data);
  }

  /**
  * 查出数据维度的公司或部门
  */
  getOfficeList(data) {
    return this.http.get(`/sys/office/getOfficeList`, data);
  }



  /**
   * 根据支付Id，查询支付附件
   */
  getWechatMsgLogList(data) {
    return this.http.post(`/sys/wechatMsgLog/getWechatMsgLogList`, data);
  }

  /**
   * 根据用户权限，查询订单列表
   */
  getOrderListByPermission(data) {
    return this.http.post(`/customer/order/orderlist`, data);
  }

  /**
   * 根据用户权限，查询任务列表
   */
  getOrderTaskList(data) {
    return this.http.post(`/customer/orderTask/orderTaskList`, data);
  }

  /**
   * @description 工单负责人更改记录
   */
  bsOrderTaskUserUpdate(data) {
    return this.http.post(`/bsOrderTaskUserUpdate/add`, data);
  }

  getAttachmentList(data) {
    return this.http.post(`/sys/attachment/list`, data);
  }

  /**
   * 根据id，删除支付记录
   */
  deletePayRecord(data) {
    return this.http.postParams(`/customer/payRecord/deletePayRecord`, data);
  }

  /**
   * 任务提成配置列表
   */
  getTaskCommissionList(data) {
    return this.http.post(`/sys/taskCommisssion/commissionList`, data);
  }

  /**
   * 新增或修改任务提成配置
   */
  addOrUpdateTaskCommission(data) {
    return this.http.post(`/sys/taskCommisssion/addOrUpdateTaskCommission`, data);
  }

  /**
   * 删除任务提成配置
   */
  delTaskCommission(data) {
    return this.http.post(`/sys/taskCommisssion/delTaskCommission`, data);
  }

  /**
   * 编辑任务提成配置（根据Id，获取提成配置）
   */
  getCommissionById(data) {
    return this.http.postParams(`/sys/taskCommisssion/getCommissionById`, data);
  }

  /**
   * 停止任务
   */
  stopTask(data) {
    return this.http.postParams(`/customer/orderTask/stopTask`, data);
  }

  /**
   * 完成任务
   */
  finishTask(data) {
    return this.http.post(`/customer/orderTask/finishTask`, data);
  }

  // #region 教程管理
  /**
   * 添加教程
   */
  addCourse(data) {
    return this.http.post(`/sys/course/add`, data);
  }

  /**
   * 修改教程
   */
  editCourse(data) {
    return this.http.post(`/sys/course/update`, data);
  }

  /**
   * 查询教程记录
   */
  getCourseList(data) {
    return this.http.post(`/sys/course/select`, data);
  }

  /**
   * 查询教程
   */
  getCourse(data) {
    return this.http.get(`/sys/course/get`, data);
  }

  /**
   * 删除教程
   */
  deleteCourse(data) {
    return this.http.postParams(`/sys/course/del`, data);
  }

  /**
   * 添加教程阅读
   */
  addCourseRead(data) {
    return this.http.postParams(`/sys/course-read/add`, data);
  }

  // #endregion


  /**
   * 任务交付记录
   */
  getTaskContinueListById(data) {
    return this.http.post(`/customer/orderTask/getTaskContinueListById`, data);
  }


  /**
   * 添加订单发票
   */
  addOrderInvoice(data) {
    return this.http.post(`/sys/invoice/add`, data);
  }

  /**
   * 根据订单ID，查询支付记录
   */
  getPayRecordlistByOrderId(data) {
    return this.http.postParams(`/customer/payRecord/getPayRecordListByOrderId`, data);
  }

  // #region 个人提成
  // 个人提成列表
  getCommissions(data) {
    return this.http.post(`/sys/commissionRecord/persionCommissionList`, data);
  }

  // #endregion
  /**
   * 获取线索城市列表
   * @param data
   */
  getPlanningPageSearch(data) {
    return this.http.post('/customer/bsCluesPlanning/pageSearch', data);
  }
  /**
   * 获取录入价格的初始化表格结构
   * @param data
   */
  getNearlyWeek(data) {
    return this.http.get('/customer/bsCluesPlanning/inputPrice/getNearlyWeek', data);
  }

  /**
   * 查询线索计划
   */
  getThreadPlan(data) {
    return this.http.post(`/system/sysThreadPlan/get`, data);
  }

  saveThreadPlan(data) {
    return this.http.post(`/system/sysThreadPlan/add`, data);
  }

  /**
   * 删除计划
   */
  deleteThreadPlan(data) {
    return this.http.postParams(`/system/sysThreadPlan/delete`, data);
  }


  /**
   * 当前线索可录入的数量
   * @param data
   */
  cueEntryQuantity(data) {
    return this.http.get(`/customer/publicCustomerResource/cueEntryQuantity`, data);
  }

  /**
   * 线索获取线索计划
   * @param
   */
  getThreadPlanList() {
    return this.http.get(`/system/sysThreadPlan/getAffterDate`);
  }

  /**
   * 编辑真实投入价格
   * @param data
   */
  editRealPrice(data) {
    return this.http.postParams(`/system/sysThreadPlan/editRealPrice`, data);
  }
  /**
   * 录入价格
   * @param data
   */
  inputPriceUpdate(data) {
    return this.http.post(`/customer/bsCluesPlanning/inputPrice/updatePrice`, data);
  }

  /**
   * 线索价格录入 保存
   * @param data
   */
  inputPriceSave(data) {
    return this.http.post(`/customer/bsCluesPlanning/inputPrice/savePrice`, data);
  }

  /**
   * 根据计划线索查询
   * 核名线索池
   * @param data
   */
  getSysThreadPlanCustomerList(data) {
    return this.http.post(`/system/sysThreadPlan/nuclearNameClues`, data);
  }
  /**
    * 根据计划线索查询
    * 查询精准线索池
    * @param data
    */
  getSysThreadPlanCustomerListJz(data) {
    return this.http.post(`/system/sysThreadPlan/accurateNameClues`, data);
  }
  /**
    *查询核名线索池(无角色限制-查询全部)
    * @param data
    */
  getAllSysThreadPlanCustomerList(data) {
    return this.http.post(`/system/sysThreadPlan/allNuclearNameClues`, data);
  }
  /**
   * 获取线索来源列表
   * @param
   */
  getClueSourceList(data) {
    return this.http.get(`/system/sysThreadPlan/getClueSourceMap/` + data.sourceFlag);
  }
  /**
   * 获取公司config
   * @param data
   */
  getOfficeConfigList(data) {
    // return this.http.post(`/system/sysOfficeConfig/getOfficeConfig`, data);
    return this.http.post(`/system/sysOfficeConfig/getOfficeConfigs`, data);
  }
  getOfficeConfig(data) {
    return this.http.post(`/system/sysOfficeConfig/get`, data);
  }

  saveOfficeConfig(data) {
    return this.http.post(`/system/sysOfficeConfig/save`, data);
  }
  // 修改余额
  updateAccount(data) {
    return this.http.post(`/sysAccountUpdate/updateAccount`, data);
  }
  /**
   * 修改公司
   * @param data
   */
  updateOfficeConfig(data) {
    return this.http.post('/system/sysOfficeConfig/update', data);
  }

  officeBbcBankQrCode(data) {
    return this.http.post(`/system/sysOfficeConfig/bbcBankQrCode`, data);
  }

  /**
   * 获取线索计划统计数据
   * @param data
   */
  getCluesStatistics(data) {
    return this.http.post(`/customer/bsCluesPlanning/getCluesStatistics`, data);
  }

  //查询有加盟商或者公司的地区
  getAreaCluesPrice() {
    return this.http.get(`/customer/bsCluesPlanning/getAreaCluesPrice`);
  }

  //查询有加盟商或者公司的地区-线索
  getAreaCluesGroupPrice() {
    return this.http.get(`/customer/bsCluesPlanning/getAreaCluesGroupPrice`);
  }

  //通过地址id查询符合的公司-线索
  getInOffice(areaId) {
    return this.http.post(`/system/sysOfficeConfig/getInOffice/${areaId}`);
  }
  //通过公司id查询符合的用户-线索
  getInUser(companyId) {
    return this.http.post(`/system/sysUserConfig/getInUser/${companyId}`);
  }

  //查询有加盟商或者公司的地区-采买线索
  getAreaPurchaseCluesGroupPrice(data) {
    return this.http.get(`/customer/bsCluesPlanning/getAreaCluesGroupPrice`, data);
  }
  /**
   *
   * @param data 新建采买线索
   */
  addPurchaseClue(data) {
    return this.http.post(`/buying/clue/add`, data);
  }
  /**
   *
   * @param data 查询采买线索列表
   */
  getPurchaseClueList(data) {
    return this.http.post(`/buying/clue/getPages`, data);
  }
  /**
   *
   * @param data 查询采买线索池
   */
  getPurchaseCluePollPagesList(data) {
    return this.http.post(`/buying/clue/poolPages`, data);
  }
  /**
   *
   * @param data 删除采买线索
   */
  deletePurchaseClue(data) {
    return this.http.get(`/buying/clue/del/` + data.id);
  }
  /**
   *
   * @param data 采买线索批量导入
   */
  importPurchaseClue(data) {
    // console.log(this.http.upload)
    return this.http.upload(data, `/buying/clue/batch/import`);
  }
  /**
   *
   * @param data 新增知识库
   */
  addKnowledgeBase(data) {
    return this.http.post(`/sys/question/add`, data);
  }

  /**
   *
   * @param data 查询知识库
   */
  getKnowledgeBase(data) {
    return this.http.post(`/sys/question/list`, data);
  }

  /**
   *
   * @param data 删除知识库
   */
  deleteKnowledgeBase(data) {
    return this.http.get(`/sys/question/del`, data);
  }

  /**
   *
   * @param data 修改知识库
   */
  editKnowledgeBase(data) {
    return this.http.post(`/sys/question/update`, data);
  }

  /**
   *
   * @param data 更新知识库数量
   */
  updataKnowledgeBase(data) {
    return this.http.get(`/sys/question/read`, data);
  }

  /**
   * 修改用户接收线索开关
   * @param data
   */
  updateUserReceiveClues(data) {
    return this.http.postParams(`/sys/user/updateUserReceiveClues`, data);
  }

  /**
   * 业绩排行查询
   * @param data
   */
  getAchivementStatistical(data) {
    return this.http.post(`/system/sysStatistical/getAchivementStatistical`, data);
  }

  /**
   * 线索统计
   * @param data
   */
  getResourseStatise(data) {
    return this.http.post(`/customer/publicCustomerResource/getResourseStatise`, data);
  }

  /**
   * 毛利统计
   * @param data
   */
  getStatisticalGrossMargin(data) {
    return this.http.get('/system/sysStatistical/getStatisticalGrossMargin', data);
  }

  /**
   * 按照类别查询毛利业绩
   * @param data
   */
  getCateMargion(data) {
    return this.http.get('/system/sysStatistical/getCateMargion', data);
  }


  /**
   * 根据公司Id，查询公司下所有的部门
   */
  findDeptByCompanyId(data) {
    return this.http.postParams(`/sys/office/findDeptByCompanyId`, data);
  }

  /**
   * 根据公司Id，查询公司下所有的部门
   */
  findCompanyByAreaId(data) {
    return this.http.postParams(`/sys/office/findCompanyByAreaId`, data);
  }

  findCompanyNoPerByAreaId(data) {
    return this.http.postParams(`/sys/office/findCompanyNoPerByAreaId`, data);
  }

  completeBatch(data) {
    return this.http.postParams(`/sys/attachment/completeBatch`, data);
  }


  /**
   * 电子合同模板列表
   * @param data
   */
  electronicTemplateList(data) {
    return this.http.post(`/contract/template/list`, data);
  }

  //删除
  electronicTemplateDel(data) {
    return this.http.get(`/contract/template/del`, data);
  }

  //状态
  electronicTemplateStatus(data) {
    return this.http.post(`/contract/template/update/status`, data);
  }

  //查询模板
  electronicTemplate(data) {
    return this.http.get(`/contract/template/get`, data);
  }

  //编辑
  electronicTemplateEdit(data) {
    return this.http.post(`/contract/template/update`, data);
  }

  //添加
  electronicTemplateAdd(data) {
    return this.http.post(`/contract/template/add`, data);
  }

  //新建合同HTML转
  attachmentHtml(data) {
    return this.http.post(`/sys/attachment/htmlcode/upload`, data);
  }

  /**
   * 删除用户
   * @param data
   */
  delUser(data) {
    return this.http.get(`/sys/user/del`, data);
  }

  /**
   * 获取公司预消费记录
   * @param data
   */
  getOfficeConsumption(data) {
    return this.http.post('/system/sysOfficeConsumption/getOfficeConsumption', data);
  }
  /**
   * 消费统计
   * @param data
   */
  getOfficeCompanyConsumption(data) {
    return this.http.post('/system/sysOfficeConfig/statisticsOfficeConsumption', data);
  }
  /**
   * 查询公司余额账单 余额明细
   * @param data
   */
  getOfficeCompanyBalanceBill(data) {
    return this.http.post('/system/sysOfficeConfig/getBalanceBill', data);
  }
  /**
    * 获取公司配置的设置信息
    * @param data
    */
  getOfficeSettingInfo(data) {
    return this.http.get(`/system/sysOfficeConfig/getSettingInfo`, data);
  }
  /**
    * 新增/更新公司配置
    * @param data
    */
  saveOfficeCompanyConfig(data) {
    return this.http.post('/system/sysOfficeConfig/saveSetting', data);
  }
  /*
   * 获取公司下的负责人信息
   * @param data
   */
  getUserOfficePrincipal(data) {
    return this.http.get(`/user/getUsers`, data);
  }
  /**
   * 创建合作人
   * @param data
   */
  saveCreateCooperationCustomer(data) {
    return this.http.post('/customer/customerInfo/createCooperationCustomer', data);
  }

  /**
   * 查询合作人推送列表
   * @param data
   */
  queryCooperationCustomerList(data) {
    return this.http.post('/customer/customerInfo/queryCooperationCustomerList', data);
  }

  /**
   * 获取合作人分发列表
   * @param data
   */
  customerCooperationList(data) {
    return this.http.post('/customer/customerCooperation/list', data);
  }

  /**
   *分发合作人
   * @param data
   */
  distribution(data) {
    return this.http.postParams('/customer/customerCooperation/distribution', data);
  }

  /**
   * 获取当前登录人的公司信息
   */
  getCurrentOffice() {
    return this.http.get('/sys/office/getCurrentOffice');
  }

  /**
   * 获取线索轮询用户
   * @param data
   */
  getPollingUser(data) {
    return this.http.get('/sys/sysUserInfo/getPollingUser', data);
  }

  getPublicSeaConfig() {
    return this.http.post('/system/sysUserConfig/getPublicSeaConfig');
  }

  getNoDealCustomerCount() {
    return this.http.get('/customer/customerInfo/getNoDealCustomerCount');
  }

  /**线索余额检查
   */
  checkLeadBalance() {
    return this.http.get('/system/sysUserConfig/checkLeadBalance');
  }


  getCurrentDeptId() {
    return this.http.get('/sys/sysUserInfo/getCurrentDeptId');
  }

  getCurrentAreaId() {
    return this.http.get('/sys/sysUserInfo/getCurrentAreaId');
  }


  // #region tab 渠道商模块
  /**
   * 我的录入-渠道商客户
   * @param data
   */
  getMyDistributionCus(data) {
    return this.http.post('/customer/distributors/myDistributionCus', data);
  }
  /**
   * 我的待分发渠道商客户
   * @param data
   */
  getDistributorsList(data) {
    return this.http.post('/customer/distributors/list', data);
  }
  /**
   * 分发渠道商客户
   * @param data
   */
  distributionDis(data) {
    return this.http.postParams('/customer/distributors/distributionDis', data);
  }
  /**
   * 批量分发渠道商客户
   * @param data
   */
  batchDistributionDis(data) {
    return this.http.post('/customer/distributors/batchDistributionDis', data);
  }
  /**
   * 新增或者修改渠道商产品提成配置
   * @param data
   */
  saveBistributorsCommission(data) {
    return this.http.post('/sys/bistributorsCommission/saveOrUpdate', data);
  }
  /**
   * 意向客户接口
   * @param data
   */
  getPotentialCustomersList(data) {
    return this.http.post('/intentionCustomer/getIntentionCustomer', data);
  }

  /**
   * 意向客户跟进反馈接口
   * @param data
   */
  followUpFeedback(data) {
    return this.http.post('/intentionCustomer/follow', data);
  }
  /**
     * 渠道商线索新增客户
     * @param data
     */
  saveDistributorsCustomer(data) {
    return this.http.post('/customer/customerInfo/insertCustomer', data);
  }
  getUserSearchValue(data) {
    return this.http.get('/sys/queryUser/search/departmentInfo', data);
  }
  /**
  * 加盟商充值
  * @param data
  */
  getBistributorsCommissionByCateId(data) {
    return this.http.post('/sys/bistributorsCommission/getByCateId', data);
  }
  /**
   * 保存渠道商产品属性信息
   * @param data
   */
  saveBaseAreaPropertyDistributors(data) {
    return this.http.post('/sys/baseAreaPropertyDistributors/save', data);
  }

  /**
  * 渠道商需求
  * 添加客户交付记录
  * @param data
  */
  addCustomerPayRecord(data) {
    return this.http.post('/customer/customerPayRecord/add', data);
  }

  /**
  * 渠道商需求
  * 渠道商查看客户交付记录
  * @param data
  */
  getCustomerPayRecordList(data) {
    return this.http.post('/customer/customerPayRecord/list', data);
  }

  /**
  * 渠道商需求
  * 查看自己填写的客户交付记录
  * @param data
  */
  getMyPayRecord(data) {
    return this.http.get('/customer/customerPayRecord/getMyPayRecord', data);
  }

  /**
  * 修改渠道商产品属性信息
  * @param data
  */
  updateBaseAreaPropertyDistributors(data) {
    return this.http.post('/sys/baseAreaPropertyDistributors/update', data);
  }
  /**
   * 渠道商属性配置列表
   * @param data
   */
  getPropertyDistributors(data) {
    return this.http.post('/sys/baseAreaPropertyDistributors/get', data);
  }

  getDisOrderInfo(data) {
    return this.http.get('/customer/order/getDisOrderInfo', data);
  }

  comfirmOrder(data) {
    return this.http.get('/customer/order/comfirmOrder', data);
  }

  /**
   * 获取订单附件提成配置
   * @param data
   */
  getDistributorsProperties(data) {
    return this.http.get('/customer/distributorsProperties/getItem', data);
  }

  getDistributorsPropertiesByCustomerId(data) {
    return this.http.get('/customer/distributorsProperties/getByCustomerId', data);
  }

  // 订单提成申请记录
  getByOrderId(orderId) {
    return this.http.post(`/sys/settlement/getByOrderId/${orderId}`);
  }


  getbaseAreaPropertyDistributorsByCateId(data) {
    return this.http.get('/sys/baseAreaPropertyDistributors/getByCateId', data);
  }

  saveDistributorsProperties(data) {
    return this.http.post('/customer/distributorsProperties/save', data);
  }
  /**
   * 根据id查询意向客户
   * @param data
   */
  getIntenCus(data) {
    return this.http.get(`/intentionCustomer/getIntentionInfo`, data);
  }

  /**
   * 获取线下充值审核列表
   * @param data
   */
  getOfflineDepositReviewList(data) {
    return this.http.post('/system/sysOfficeConfig/getOfflineDepositReviewList', data);
  }
  /**
   *
   * @param data 线下充值详情
   */
  systemOfficeConfigDetail(payId) {
    return this.http.post("/system/sysOfficeConfig/detail/" + payId)
  }
  /**
   * @param data
   * 线下充值
   */
  offlineDeposit(data) {
    return this.http.post(`/system/sysOfficeConfig/offlineDeposit`, data);
  }
  /**
   * 审核加盟商线下充值
   * @param data
   *
   */
  reviewOfflineDeposit(data) {
    // reviewOfflineDeposit 旧审核
    return this.http.post(`/system/sysOfficeConfig/reviewOfflineDepositNew`, data);
  }
  /**
   * 领取线索
   * @param data
   */
  receiveClue(data) {
    return this.http.postParams(`/customer/customerJob/receiveClue`, data);
  }
  // #endregion

  /**
 * 获取线索删除记录
 * @param data
 *
 */
  getThreadDeleteRecordList(data) {
    return this.http.post(`/customerDelRecord/getResourceDelRecordHistory`, data);
  }
  /**
 * 获取线索删除审核列表
 * @param data
 *
 */
  getThreadDeleteAuditList(data) {
    return this.http.post(`/customerDelRecord/getResourceDelRecord`, data);
  }
  /**
 * 审核线索删除记录
 * @param data
 *
 */
  reviewDeleteRecord(data) {
    return this.http.post(`/customerDelRecord/reviewDelete`, data);
  }
  /**
 * 删除一条线索
 * @param data
 *
 */
  deleteNotThread(data) {
    return this.http.post(`/customerDelRecord/applyDelete`, data);
  }
  /**
 * 重置用户密码
 * @param data
 *
 */
  sysUserResetPassword(userId) {
    return this.http.post(`/sys/user/resetUserPassword/` + userId);
  }
  /**
   * 精准线索列表
   * @param data
  */
  getSourceConfigPreciseList(data) {
    return this.http.get(`/customer/sysSourceConfig/preciseList`, data);
  }
  /**
   *
   * @param data 精准线索列表
   */
  getUserConfigPrecise(userId) {
    return this.http.get(`/customer/sysSourceConfig/preciseList/` + userId);
  }
  /**
* 保存用户来源配置关联
* @param data
*
*/
  saveUserConfigPreciseList(data) {
    return this.http.post(`/customer/sysUserSourceConfig/saveSysUserSourceConfigList`, data);
  }
  /**
   *
   * @param data 精准线索列表
   */
  getUserResourceList(userId) {
    return this.http.get(`/customer/sysUserSourceConfig/get/` + userId);
  }
  /**
   *
   * @param data 采买线索来源列表
   */
  getPurchaseClueResourceList() {
    return this.http.post(`/customer/sysSourceConfig/getBuyingClues`);
  }
  /**
* 下载合同审核数据
* @param data
*
*/
  contractDownLoad(data) {
    return this.http.post(`/sys/downLoad/payVerifyDownLoad`, data, { responseType: 'blob' });
  }
  /**
* 根据手机号码查询客户信息
* @param data
*
*/
  getCustomerPhoneInfo(data) {
    return this.http.get(`/customer/customerInfo/getPhoneCustomerInfo`, data);
  }
  /**
* 支付记录-取消支付
* @param data
*
*/
  tCustomerCancelPayOrder(data) {
    return this.http.post(`/customer/payRecord/cancelOrderRecord`, data);
  }
  /**
* 新-退款审核
* @param data
*
*/
  orderRefundAudit(data) {
    // refundAudit 旧审核
    return this.http.post(`/sys/orderRefunds/refundAuditNew`, data);
  }

  /**
* 获取签约企业信息(分页)
* @param data
*
*/
  getSignedEnterpriseList(data) {
    return this.http.post(`/sys/signedEnterprise/get/signedEnterprises`, data);
  }
  /**
* 获取签约企业信息(分页)
* @param data
*
*/
  getMySignedEnterprises(data) {
    return this.http.post(`/sys/signedEnterprise/get/mySignedEnterprises`, data);
  }
  /**
* 查询账户收支明细接口
* @param data
*
*/
  queryInExpDetail(data) {
    return this.http.post(`/sys/signedEnterprise/queryInExpDetailPage`, data);
  }
  /**
* 保存签约企业信息
* @param data
*
*/
  addSignedEnterprise(data) {
    return this.http.post(`/sys/signedEnterprise/add`, data);
  }
  /**
 * 获取(开户行支行)支付行号,12位数字
 * @param data
 *
 */
  getSignedEnterpriseUnionPay(data) {
    return this.http.post(`/sys/signedEnterprise/getUnionPay`, data);
  }
  /**
* 查看二维码
* @param data
*
*/
  getQRCode(data) {
    return this.http.get(`/sys/signedEnterprise/getQRCode`, data);
  }

  /**
* 删除签约企业信息
* @param data
*
*/
  deleteSignedEnterprise(id) {
    return this.http.post(`/sys/signedEnterprise/delete/${id}`);
  }

  /**
* 启用OR禁用(签约企业)
* @param data
*
*/
  ifEnableSignedEnterprise(data) {
    return this.http.get(`/sys/signedEnterprise/ifEnable`, data);
  }
  /**
* 提现(签约企业)
* @param data
*
*/
  withdrawalApply(data) {
    return this.http.post(`/sys/signedEnterprise/withdrawApply`, data);
  }

  /**
* 查看签约企业信息详情
* @param data
*
*/
  getSignedEnterpriseDetail(id) {
    return this.http.get(`/sys/signedEnterprise/allin/detail/${id}`);
  }

  /**
* 编辑签约企业信息
* @param data
*
*/
  editSignedEnterprise(data) {
    return this.http.post(`/sys/signedEnterprise/edit`, data);
  }

  /**
* 根据parentCode 查询属性配置
* @param data
*
*/
  getSysParametersByParentCode(data) {
    return this.http.get(`/sys/parameters/getSysParametersByParentCode`, data);
  }

  // 根据parentCode 查询属性配置 所在行业
  getSysParametersTreeByCode(data) {
    return this.http.get(`/sys/parameters/getSysParametersTreeByCode`, data);
  }

  /**
* 添加服务商入住信息
* @param data
*
*/
  addStoragesRequestCustomerData(data) {
    return this.http.post(`/customer/provider/save`, data);
  }

  /**
* 我的入驻服务商列表
* @param data
*
*/
  getStoragesRequestCustomerListPage(data) {
    return this.http.post(`/customer/provider/listPage`, data);
  }
  /**
 * 审核入驻服务商列表
 * @param data
 *
 */
  getServiceProviderList(data) {
    return this.http.post(`/customer/provider/approveListPage`, data);
  }
  /**
* 审核入驻信息
* @param data
*
*/
  ServiceauditProvider(data) {
    // auditProvider 旧审核
    return this.http.post(`/customer/provider/auditProviderNew`, data);
  }

  /**
* 查询入驻服务商详情信息
* @param data
*
*/
  getStoragesRequestCustomerDetail(data) {
    return this.http.get(`/customer/provider/getDetail`, data);
  }

  /**
   * @description 入库上传文件
   */
  storagesUploadWithController() {
    return `/sys/attachment/uploadWithController`;
  }

  /**
 * @description 入库上传文件
 */
  storagesUploadWithController2(data) {
    return this.http.upload(data, `/sys/attachment/uploadWithController`);
  }

  /**
   * @description 请款 上传支付凭证
   */
  updateVoucherRequestPayments(data) {
    return this.http.post(`/sys/request/payment/updateVoucher`, data);
  }

  // 服务商请款申请
  // 获取服务商请款列表数据
  getSpRequestPayments(data) {
    return this.http.post(`/sys/request/payment/get/spRequestPayments`, data);
  }
  // 添加关联订单 列表数据
  getRelationOrders(data) {
    return this.http.post(`/sys/request/payment/get/relationOrders`, data);
  }
  // 获取服务商信息
  getServiceProviders(data) {
    return this.http.post(`/sys/request/payment/get/serviceProviders`, data);
  }
  // 获取付款公司列表
  getPaymentCompanyList(data) {
    return this.http.get(`/sys/request/payment/getPaymentCompanies`, data);
  }
  // 服务商信息请款金额
  getSpRequestPaymentsAmount(data) {
    return this.http.post(`/sys/request/payment/get/getSpRequestPaymentsAmount`, data);
  }
  /**
  提成结算申请-查询订单
   */
  getSettlementOrder(data) {
    return this.http.post(`/sys/settlement/getSettlementOrder`, data);
  }

  /**
  提成结算申请-查询工单
   */
  getSettlementTask(data) {
    return this.http.post(`/sys/settlement/getSettlementTask`, data);
  }
  /**
提成结算申请-查询异地工单
 */
  getSettlementYdTask(data) {
    return this.http.post(`/sys/settlement/getSettlementTaskDifferent`, data);
  }
  /**
  提成结算审核列表
   */
  getApproveSettlementList(data) {
    return this.http.post(`/sys/settlement/approveSettlementPage`, data);
  }
  /**
    提成结算审核信息
    */
  commissiSettlementExamine(data) {
    // 旧审核流程
    // return this.http.post(`/sys/settlement/approveSettlement`, data);
    // 新审核流程 更换
    return this.http.post(`/sys/settlement/approveSettlementNew`, data);
  }
  /**
    提成结算审核信息 驳回
    */
  rejectSettlement(data) {
    return this.http.post(`/sys/settlement/rejectSettlement`, data);
  }
  // 保存服务商请款申请信息
  getPaymentAdd(data) {
    return this.http.post(`/sys/request/payment/add`, data);
  }

  // 编辑服务商请款申请信息 发票
  getPaymentEdit(data) {
    return this.http.post(`/sys/request/payment/edit`, data);
  }
  // 编辑服务商请款申请信息
  getPaymentEditInfo(data) {
    return this.http.post(`/sys/request/payment/editInfo`, data);
  }

  // 获取请款审核列表数据
  getRequestPaymentChecks(data) {
    return this.http.post(`/sys/request/payment/get/requestPaymentChecks`, data);
  }
  // 获取服务商请款详情数据
  getPaymentDetail(data) {
    return this.http.post(`/sys/request/payment/detail/` + data);
  }

  // 删除发票数据
  attachmentDel(id) {
    return this.http.post(`/requestPaymentAttachment/delete/${id}`);
  }
  /**
  * 请款审核入驻信息
  * @param data
  *
  */
  serviceRequestPayments(data) {
    // requestPayments 旧审核
    return this.http.post(`/sys/request/payment/check/requestPaymentsNew`, data);
  }
  /**
* 根据checkType 和 openInvoiceType 查询属性配置
* @param data
*
*/
  getCheckOptionData(data) {
    return this.http.post(`/sys/request/payment/getCheckOptionData`, data);
  }
  /**
* 提成结算申请录入
* @param data
*
*/
  addCommissinData(data) {
    return this.http.post(`/sys/settlement/insertSettlement`, data);
  }
  /**
* 查询我的提成结算申请列表
* @param data
*
*/
  queryMyCommissinData(data) {
    return this.http.post(`/sys/settlement/queryMySettlementPage`, data);
  }
  /**
* 提成汇总 = 查询公司下成员
* @param data
*
*/
  queryMyCommissinDataList(data) {
    return this.http.post(`/sys/settlement/dataList`, data);
  }
  /**
* 提成汇总 = 结算
* @param data
*
*/
  sysOfficeSettlementCommissin(data) {
    return this.http.post(`/sysOfficeSettlement/settlement`, data);
  }

  /**
* 提成汇总 = 查询开启公司列表数据
* @param data
*
*/
  getSysOfficeSettlementOfficeList() {
    return this.http.post(`/sysOfficeSettlement/officeList`);
  }

  /**
* 加盟商 = 提成开启0否1是
* @param data
*
*/
  sysOfficeConfigSetCommissionFlag(data) {
    return this.http.post(`/system/sysOfficeConfig/setCommissionFlag`, data);
  }

  /**
* 查看提成结算申请详情
* @param data
*
*/
  getCommissinSettlementDetail(data) {
    return this.http.get(`/sys/settlement/getSettlementDetail`, data);
  }
  /**
* 编辑合作框架协议
* @param data
*
*/
  editProviderUploadImg(data) {
    return this.http.post(`/customer/provider/editProvider`, data);
  }
  /**
* 根据code获取文件模板
* @param data
*
*/
  getTemplateUpload(data) {
    return this.http.get(`/sys/attachment/getModeWithCode`, data);
  }

  /**
* 查询订单日志
* @param data
*
*/
  getTqueryOrderLog(data) {
    return this.http.get(`/order/log/queryCurrentUserOrderLog`, data);
  }

  /**
* 保存订单日志
* @param data
*
*/
  saveOrderLog(data) {
    return this.http.post(`/order/log/saveOrderLog`, data);
  }

  /**
* 保存草稿
* @param data
*
*/
  settlementDraftSend(data) {
    return this.http.post(`/sys/settlement/insertDraft`, data);
  }

  /**
* 删除草稿
* @param data
*
*/
  settlementDeleteDraft(data) {
    return this.http.get(`/sys/settlement/deleteDraft`, data);
  }

  /**
* 获取当前用户草稿Id
* @param data
*
*/
  settlementGetDraftId() {
    return this.http.get(`/sys/settlement/getDraftId`);
  }
  /**
 * 删除服务商入住草稿
 * @param data
 *
 */
  customerDeleteDraft(data) {
    return this.http.get(`/customer/provider/deleteDraft`, data);
  }

  /**
  * 获取我的服务商入驻 草稿信息Id
  * @param data
  *
  */
  customerGetDraftId() {
    return this.http.get(`/customer/provider/getDraftId`);
  }

  /**
* 暂存服务商入住信息
* @param data
*
*/
  customerDraftSend(data) {
    return this.http.post(`/customer/provider/saveDraft`, data);
  }


  /**
* 删除服务商请款申请信息-草稿
* @param data
*
*/
  requestDeleteDraft(data) {
    return this.http.get(`/sys/request/payment/deleteDraft`, data);
  }

  /**
* 获取服务商请款申请信息-草稿id
* @param data
*
*/
  requestGetDraftId() {
    return this.http.get(`/sys/request/payment/getDraftId`);
  }

  /**
* 保存服务商请款申请信息-草稿
* @param data
*
*/
  requestDraftSend(data) {
    return this.http.post(`/sys/request/payment/insertDraft`, data);
  }
  /**
* 通过编号查询审核金额限制
* @param data
*
*/
  findByCode(code) {
    return this.http.post(`/sys/parameters/findByCode/${code}`);
  }

  //请款发票导入
  uploadWithController(data) {
    return this.http.upload(data, `/bsServiceProviderRequestPaymentInvoice/uploadWithController`);
  }
  // 编辑服务商请款申请信息 发票(新)
  uploadWithControllerSave(data) {
    return this.http.post(`/bsServiceProviderRequestPaymentInvoice/saveList`, data);
  }
  /**
* 服务商请款申请信息 退回
* @param data
*
*/
  requestDraftSendRefund(data) {
    return this.http.post(`/bsServiceProviderRequestPaymentRefund/saveInfo`, data);
  }
  /**
* 服务商请款 退回列表 审核
* @param data
*
*/
  paymentRefundList(data) {
    return this.http.post(`/bsServiceProviderRequestPaymentRefund/dataList`, data);
  }
  /**
* 服务商请款 退回列表 我的
* @param data
*
*/
  paymentRefundMyDataList(data) {
    return this.http.post(`/bsServiceProviderRequestPaymentRefund/myDataList`, data);
  }
  /**
* 服务商请款 退回列表 我的
* @param data
*
*/
  getPaymentDetailBack(id) {
    return this.http.post(`/bsServiceProviderRequestPaymentRefund/detail/${id}`);
  }
  /**
* 服务商请款 退回 审核通过
* @param data
*
*/
  getDetailBackConfirm(data) {
    return this.http.post(`/bsServiceProviderRequestPaymentRefund/confirm`, data);
  }
  /**
* 服务商请款 退回 审核不通过
* @param data
*
*/
  getDetailBackUnPass(data) {
    return this.http.post(`/bsServiceProviderRequestPaymentRefund/unPass`, data);
  }
  /**
* 工单 我发起的 列表信息
* @param data
*
*/
  getWorkOrderList(data) {
    return this.http.post(`/iStarted/orderTask/getIStarts`, data);
  }

  /**
  * 工单 我发起的 新建工单 
  * @param data
  *
  */
  addIStarted(data) {
    return this.http.post(`/iStarted/orderTask/addIStarted`, data);
  }
  /**
  * 工单 我发起的 验收 
  * @param data
  *
  */
  acceptance(data) {
    return this.http.post(`/iStarted/orderTask/acceptance`, data);
  }
  /**
  * 工单 我发起的 停止 
  * @param data
  *
  */
  stopWorkOrder(data) {
    return this.http.post(`/iStarted/orderTask/stop`, data);
  }
  /**
  * 工单 我发起的 驳回 
  * @param data
  *
  */
  rejectWorkOrder(data) {
    return this.http.post(`/iStarted/orderTask/reject`, data);
  }


  /**
* 获取企业微信 授权
* @param data
*
*/
  getWorkLoginPathData() {
    return this.http.get(`/sys/login/qw/getWorkLoginPath`);
  }

  /**
* 企业微信绑定
* @param data
*
*/
  getWorkLoginPathBand(userId) {
    return this.http.post(`/sys/login/qw/user/getWorkLoginPathBand/${userId}`);
  }
  /**
* 接触企业微信绑定
* @param data
*
*/
  unBandQw() {
    return this.http.post(`/sys/user/unBandQw`);
  }

  /**
 * 查询当前用户所在公司相同区域的公司
 * @param data
 *
 */
  getSameAreaOfficeCompantt() {
    return this.http.get(`/sys/office/getSameAreaOffice`);
  }

  /**
  * 添加订单联合签约人 
  * @param data
  *
  */
  addUnionUserOrder(data) {
    return this.http.post(`/customer/order/addRelevanceUser`, data);
  }

  /**
  * 获取企微绑定配置
  * @param data
  *
  */
  getofficeQwConfig() {
    return this.http.get(`/sys/officeQwConfig/get`);
  }

  /**
  * 新增企微绑定配置
  * @param data
  *
  */
  addofficeQwConfig(data) {
    return this.http.post(`/sys/officeQwConfig/add`, data);
  }

  /**
  * 新增企微绑定配置
  * @param data
  *
  */
  updateofficeQwConfig(data) {
    return this.http.post(`/sys/officeQwConfig/update`, data);
  }

  /**
  * 新增企微 通知绑定
  * @param data
  *
  */
  messageNoticeBindQwConfig(data) {
    return this.http.post(`/sys/officeQwConfig/messageNoticeBind`, data);
  }

  /**
  * 选择组织架构 签约企业
  * @param data
  *
  */
  getOfficesBySignedEnterpriseIdList(data) {
    return this.http.get(`/sys/officeSignedEnterprise/getOfficesBySignedEnterpriseId`, data);
  }

  /**
* 更新 选择组织架构 签约企业
* @param data
*
*/
  updateOfficesBySignedEnterpriseId(data) {
    return this.http.post(`/sys/signedEnterprise/update`, data);
  }

  /**
 * 查询指定公司的所有用户
 * @param data
 *
 */
  getUserInfoByOfficeIdList(data) {
    return this.http.get(`/sys/sysUserInfo/getUserInfoByOfficeId`, data);
  }

  /**
* 保存订单甩单信息
* @param data
*
*/
  addSingleOrder(data) {
    return this.http.post(`/customer/order/saveOrderSingle`, data);
  }

  /**
* 甩单信息
* @param data
*
*/
  getmyOrderSingleList(data) {
    return this.http.post(`/customer/order/myOrderSingleList`, data);
  }

  /**
* 接收编辑甩单订单
* @param data
*
*/
  editMyOrderSingle(data) {
    return this.http.post(`/customer/order/editOrderSingle`, data);
  }

  /**
* 拒绝，撤回甩单订单
* @param data
*
*/
  userUpdateOrderSingleStatus(data) {
    return this.http.post(`/customer/order/updateOrderSingleStatus`, data);
  }

  /**
  * 团队 甩单信息
  * @param data
  *
  */
  getteamOrderSingleLists(data) {
    return this.http.post(`/customer/order/teamOrderSingleList`, data);
  }

  /**
  * 团队 分配甩单订单
  * @param data
  *
  */
  assignOrderSingles(data) {
    return this.http.post(`/customer/order/assignOrderSingle`, data);
  }

  /**
* 甩单结算申请 结算接收方
* @param data
*
*/
  getNotInitiatorCompany(data) {
    return this.http.get(`/sys/office/getNotInitiatorCompany`, data);
  }

  /**
* 甩单结算申请 甩单结算申请分页列表
* @param data
*
*/
  getClearingGetPages(data) {
    return this.http.post(`/sys/order/single/clearing/getPages`, data);
  }

  /**
* 甩单结算申请 获取甩单结算申请详情
* @param data
*
*/
  getClearingDetail(data) {
    return this.http.post(`/sys/order/single/clearing/detail/` + data);
  }

  /**
  * 甩单结算申请 甩单结算申请
  * @param data
  *
  */
  singleClearingAdd(data) {
    return this.http.post(`/sys/order/single/clearing/add`, data);
  }

  /**
* 甩单结算申请 存储草稿
* @param data
*
*/
  saveOrUpdateDraft(data) {
    return this.http.post(`/sys/order/single/clearing/saveOrUpdateDraft`, data);
  }

  /**
* 获取甩单结算申请-草稿id
* @param data
*
*/
  getCurrentDraftId() {
    return this.http.post(`/sys/order/single/clearing/getCurrentDraftId`);
  }

  /**
* 删除甩单结算申请-草稿
* @param data
*
*/
  deleteDraftById(data) {
    return this.http.get(`/sys/order/single/clearing/deleteDraftById`, data);
  }

  /**
*城市经理审核
* @param data
*
*/
  managePayVerifyofflinePayJL(data) {
    return this.http.post(`/customer/offlinePay/managePayVerify`, data);
  }

  /**
* 获取用户部门和公司接口
* @param data
*
*/
  getSysUserInfos(id) {
    return this.http.get(`/sys/user/getSysUserInfo/${id}`);
  }

  /**
 * 根据客户ID，选择公司信息 
 * @param data
 *
 */
  getCompanySelectByCusIds(data) {
    return this.http.get(`/customer/bsCompany/getCompanySelectByCusId`, data);
  }


  /**
*修改 工单公司
* @param data
*
*/
  updateOrderTaskCompanys(data) {
    return this.http.post(`/customer/orderTask/update`, data);
  }

  /**
*获取订单关联工单列表
* @param data
*
*/
  orderTaskLists(data) {
    return this.http.post(`/customer/orderTask/orderTaskLists`, data);
  }

  // 报销
  /**
*获取草稿详情 - 报销
* @param data
*
*/
  getUserReimbursementsApplyDeatails(data) {
    return this.http.post(`/sysUserApply/draftDetail`, data);
  }

  /**
 * 获取申请报销公司
 * @param data
 *
 */
  getUserReimbursementsApplyCompanies() {
    return this.http.get(`/sysUserApply/getPaymentCompanies`);
  }

  /**
 * 报销名目列表数据
 * @param data
 *
 */
  getReimbursementsApplyNominaList() {
    return this.http.get(`/sysUserApplyNominal/listData`);
  }

  /**
 * 报销申请
 * @param data
 *
 */
  userReimbursementsApplyAdd(data) {
    return this.http.post(`/sysUserApply/insertSysUserApply`, data);
  }
  /**
* 报销申请 -获取收款方信息
* @param data
*
*/
  accountFindList(data) {
    return this.http.post(`/sysUserApplyAccount/findList`, data);
  }
  /**
* 删除收款方信息
* @param data
*
*/
  delAccountFind(id) {
    return this.http.post(`/sysUserApplyAccount/del/${id}`);
  }
  /**
 * 财务编辑
 * @param data
 *
 */
  updateSysUserApplyById(data) {
    return this.http.post(`/sysUserApply/updateSysUserApplyById`, data);
  }

  /**
 * 报销申请列表
 * @param data
 *
 */
  getUserReimbursementsApplyList(data) {
    return this.http.post(`/sysUserApply/sysUserApplyList`, data);
  }

  /**
 * 获取报销申请详情
 * @param data
 *
 */
  getUserReimbursementsApplyDetail(id) {
    return this.http.post(`/sysUserApply/detail/` + id);
  }
  /**
 * 报销 修改回单
 * @param data
 *
 */
  userReimbursementsApplyPpdate(data) {
    return this.http.post(`/sysUserApplyAttachment/update`, data);
  }

  /**
 * 报销草稿保存
 * @param data
 *
 */
  userReimbursementsApplySend(data) {
    return this.http.post(`/sysUserApply/insertDraftSysUserApply`, data);
  }

  /**
 * 删除报销草稿
 * @param data
 *
 */
  userReimbursementsApplyDelete(id) {
    return this.http.post(`/sysUserApply/delete/` + id);
  }

  /**
   * 报销（草稿）修改
   * @param data
   *
   */
  userReimbursementsApplyUpdate(data) {
    return this.http.post(`/sysUserApply/updateSysUserApply`, data);
  }

  /**
  *获取所有申请报销公司
  * @param data
  *
  */
  getAllCompany() {
    return this.http.get(`/sysUserApply/getAllPaymentCompanies`);
  }
  /**
  *获取报销审核列表
  * @param data
  *
  */
  getReimbursementVerifyList(data) {
    return this.http.post(`/sysUserApply/checkList`, data);
  }
  /**
  * 报销审核
  * @param data
  *
  */
  reimbursementCheck(data) {
    // /sysUserApply/check 旧的审核
    return this.http.post(`/sysUserApply/checkNew`, data);
  }
  /**
  * 报销核销审核列表
  * @param data
  *
  */
  getwriteOffVerifyList(data) {
    return this.http.post(`/sysUserApplyVerification/checkList`, data);
  }
  /**
  * 报销核销审核
  * @param data
  *
  */
  writeOffCheck(data) {
    // check
    return this.http.post(`/sysUserApplyVerification/checkNew`, data);
  }
  /**
  * 甩单结算审核列表
  * @param data
  *
  */
  getFlingOrderVerifyList(data) {
    return this.http.post(`/sys/order/single/clearing/getCheckPages`, data);
  }
  /**
  * 甩单结算审核
  * @param data
  *
  */
  flingOrderCheck(data) {
    return this.http.post(`/sys/order/single/clearing/check`, data);
  }

  //核销
  /**
  * 通过公司查询借款记录
  * @param data
  *
  */
  findUserApplyCompanyListId(data) {
    return this.http.post(`/sysUserApply/findSysUserApplyListByEnterpriseId`, data);
  }

  /**
  *获取草稿详情 - 核销
  * @param data
  *
  */
  getUserVerificationApplyDeatails(data) {
    return this.http.post(`/sysUserApplyVerification/draftDetail`, data);
  }

  /**
  *核销申请(新增)
  * @param data
  *
  */
  getUserVerificationApplyADD(data) {
    return this.http.post(`/sysUserApplyVerification/insertSysUserVerificationApply`, data);
  }

  /**
  *核销草稿保存
  * @param data
  *
  */
  getUserVerificationApplySen(data) {
    return this.http.post(`/sysUserApplyVerification/insertDraftSysUserVerificationApply`, data);
  }

  /**
 *核销 编辑
 * @param data
 *
 */
  getUserVerificationApplyUpdateById(data) {
    return this.http.post(`/sysUserApplyVerification/updateById`, data);
  }


  /**
  *核销申请列表
  * @param data
  *
  */
  getUserVerificationApplyLists(data) {
    return this.http.post(`/sysUserApplyVerification/sysUserApplyVerificationList`, data);
  }

  /**
 * 删除核销草稿
 * @param data
 *
 */
  userVerificationApplyDelete(id) {
    return this.http.post(`/sysUserApplyVerification/delete/` + id);
  }

  /**
   * 获取核销详情
   * @param data
   *
   */
  getUserVerificationApplyDetail(id) {
    return this.http.post(`/sysUserApplyVerification/detail/` + id);
  }

  /**
   * 提成结算申请-查询甩单订单
   * @param data
   *
   */
  getUserSettlementSingleOrders(data) {
    return this.http.post(`/sys/settlement/getSettlementSingleOrder`, data);
  }
  /**
   * 人才管理 - 入职待申请 - 员工信息管理
   * @param data
   *
   */
  userInductionList(data) {
    return this.http.post(`/sysUserInduction/userInductionList`, data);
  }
  /**
   * 人才管理 - 入职待申请
   * @param data
   *
   */
  employeesList(data) {
    return this.http.post(`/sysUserInduction/employeesList`, data);
  }

  /**
   * @description 获取公司列表数据 人才管理
   */
  sysUserInductionCompany() {
    return this.http.post(`/sysUserInductionCompany/listData`);
  }

  /**
   * @description 新增 修改部门类别 人才管理
   */
  saveOrUpdateDept(data) {
    return this.http.post(`/sysUserInductionDept/saveOrUpdate`, data);
  }
  /**
   * @description 删除 修改部门类别 人才管理
   */
  saveOrDelDept(data) {
    return this.http.post(`/sysUserInductionDept/del/${data.id}`);
  }

  /**
   * @description 新增 修改部门类别 公司
   */
  saveOrUpdateComp(data) {
    return this.http.post(`/sysUserInductionCompany/saveOrUpdate`, data);
  }
  /**
   * @description 删除 修改部门类别 公司
   */
  saveOrDelComp(data) {
    return this.http.post(`/sysUserInductionCompany/del/${data.id}`);
  }

  /**

  * 用户明细
  * @param data
  *
  */
  getSysUserInductionDetail(id) {
    return this.http.get(`/sysUserInduction/details/${id}`);
  }

  /**
   * 用户补全信息
   * @param data
   *
   */
  updatePerfectUserInductionDetail(data) {
    return this.http.post(`/sysUserInduction/updatePerfectUserInduction`, data);
  }


  /*
  * 人才管理 - 查询用户信息
  * @param data
  *
  */
  getUserDetailInduction(data) {
    return this.http.get(`/sysUserInduction/detail/` + data);
  }
  /**
   * 人才管理 - 添加员工 入职
   * @param data
   *
   */
  updateUserInduction(data) {
    return this.http.post(`/sysUserInduction/updateUserInduction`, data);
  }
  /**
   * 人才管理 - 添加员工 编辑
   * @param data
   *
   */
  editUserInduction(data) {
    return this.http.post(`/sysUserInduction/editUserInduction`, data);
  }

  /**
   * 人才管理-添加待入职
   * @param data
   *
   */
  addUserInduction(data) {
    return this.http.post(`/sysUserInduction/addUserInduction`, data);
  }
  /**
   * 人才管理-用户作废
   * @param data
   *
   */
  updateUserInductionInvalid(data) {
    return this.http.post(`/sysUserInduction/updateUserInductionInvalid/` + data.id);
  }
  /**
   * 人才管理-员工删除
   * @param data
   *
   */
  deleteById(data) {
    return this.http.post(`/sysUserInduction/deleteById/` + data.id);
  }
  /**
   * 人才管理-用户操作（人事操作 转正 调岗 晋升 调薪 离职）
   * @param data
   *
   */
  updateUserInductionPositive(data) {
    return this.http.post(`/sysUserInductionOptionDetail/updateUserInductionPositive`, data);
  }
  /**
  * 
  创建账号-通过工号查询员工信息
  * @param data
  *
  */
  getDetailForCreateAccount(data) {
    return this.http.get(`/sysUserInduction/detailForCreateAccount/` + data.workNumber);
  }
  /**
   * 仪表盘-订单分析-数据汇总 基本信息
   * @param data
   *
   */
  getBasicInfo(data) {
    return this.http.post(`/reportStatisticalDay/dataSum/basicInfo`, data);
  }
  /**
   * 仪表盘-订单分析-数据汇总 自营订单统计
   * @param data
   *
   */
  getZyOrderStatistics(data) {
    return this.http.post(`/reportStatisticalDay/dataSum/zyOrderStatistics`, data);
  }
  /**
   * 仪表盘-订单分析-数据汇总 业务来源占比
   * @param data
   *
   */
  getBsProportion(data) {
    return this.http.post(`/reportStatisticalDay/dataSum/bsProportion`, data);
  }
  /**
   * 仪表盘-订单分析-数据汇总 业务类型占比
   * @param data
   *
   */
  getBtProportion(data) {
    return this.http.post(`/reportStatisticalDay/dataSum/btProportion`, data);
  }
  /**
   * 仪表盘-订单分析-查询城市数据统计图
   * @param data
   *
   */
  findStatisticalCityOrdeReport(data) {
    return this.http.post(`/reportCateStatistical/findStatisticalFigure`, data);
  }

  /**
  * 仪表盘-订单分析-查公司部门
  * @param data
  *
  */
  getOfficeDepartTreeWithPermissions() {
    return this.http.get(`/sys/office/getOfficeDepartTreeWithPermissions`);
  }

  /**
  * 查公司部门 无权限限制
  * @param data
  *
  */
  getOfficeDepartTrees() {
    return this.http.get(`/sys/office/getOfficeDepartTree`);
  }

  /**
   * 仪表盘-订单分析-查询成员 业务来源
   * @param data
   *
   */
  findUserOrderCourceCountReport(data) {
    return this.http.post(`/reportCateStatistical/findUserOrderCourceCount`, data);
  }

  /**
   * 仪表盘-订单分析-查询成员订单数据
   * @param data
   *
   */
  findUserOrderDetailReport(data) {
    return this.http.post(`/reportCateStatistical/findUserOrderDetail`, data);
  }

  /**
   * 仪表盘-订单分析-成员业务类型统计
   * @param data
   *
   */
  findUserOrderCateCountReport(data) {
    return this.http.post(`/reportCateStatistical/findUserOrderCateCount`, data);
  }

  /**
   * 仪表盘-客户数据统计图
   * @param data
   *
   */
  findCustomerStatisticalCityReport(data) {
    return this.http.post(`/reportCateStatistical/findCustomerStatisticalFigure`, data);
  }

  /**
   * 仪表盘-客户数据 查询成员关联公司统计
   * @param data
   *
   */
  findCustomerCityCompanyCountReport(data) {
    return this.http.post(`/reportCateStatistical/findCustomerCompanyCount`, data);
  }

  /**
   * 仪表盘-客户数据 成员客户来源统计
   * @param data
   *
   */
  findCustomerSourceCountReport(data) {
    return this.http.post(`/reportCateStatistical/findCustomerSourceCount`, data);
  }


  /**
   * 仪表盘-客户分析-数据汇总 基本信息
   * @param data
   *
   */
  custNumCount(data) {
    return this.http.post(`/reportStatisticalDay/dataSum/custNumCount`, data);
  }

  /**
   * 仪表盘-客户分析-数据汇总 客户来源占比
   * @param data
   *
   */
  custSourceRatio(data) {
    return this.http.post(`/reportStatisticalDay/dataSum/custSourceRatio`, data);
  }
  /**
   * 仪表盘-客户分析-数据汇总 客户消费统计
   * @param data
   *
   */
  custConsumeStats(data) {
    return this.http.post(`/reportStatisticalDay/dataSum/custConsumeStats`, data);
  }

  /**
   * 仪表盘-客户数据 客户消费客单价复购
   * @param data
   *
   */
  findCustomerInfoCountReport(data) {
    return this.http.post(`/reportCateStatistical/findCustomerInfoCount`, data);
  }

  /**
   * 客户预收款订单-保存
   * @param data
   *
   */
  addCustomerAdvanceOrders(data) {
    return this.http.post(`/bsCustomerAdvanceOrder/addCustomerAdvanceOrder`, data);
  }

  /**
   * 客户预收款订单-数据列表
   * @param data
   *
   */
  orderlistPrepaymentListPaeges(data) {
    return this.http.post(`/bsCustomerAdvanceOrder/listPage`, data);
  }

  /**
   * 客户预收款订单-取消
   * @param data
   *
   */
  orderlistPrepaymentCancel(id) {
    return this.http.post(`/bsCustomerAdvanceOrder/cancel/` + id);
  }

  /**
  * 
  客户预收款订单-详情
  * @param data
  *
  */
  orderlistPrepaymentDetail(id) {
    return this.http.get(`/bsCustomerAdvanceOrder/detail/` + id);
  }

  /**
   * 订单预收款订单-列表数据
   * @param data
   *
   */
  orderPrepaymentListLogs(data) {
    return this.http.post(`/bsCustomerAdvanceOrderLog/listLogs`, data);
  }
  /**
  * 订单预收款订单-支付列表
  * @param data
  *
  */
  advancePaymentList(advanceOrderId) {
    return this.http.post(`/customer/payRecord/advancePaymentList/${advanceOrderId}`);
  }

  /**
   * 订单预收款订单-客户的预收款
   * @param data
   *
   */
  orderPrepaymentListAdvanceOrderList(data) {
    return this.http.post(`/bsCustomerAdvanceOrder/customerAdvanceOrderList`, data);
  }

  /**
   * 订单预收款订单-通过预收款支付
   * @param data
   *
   */
  orderPrepaymentListAdvanceOrderPay(data) {
    return this.http.post(`/bsCustomerAdvanceOrder/pay`, data);
  }

  /**
 * 客户预收款订单-使用数据列表
 * @param data
 *
 */
  useListPage(data) {
    return this.http.post(`/bsCustomerAdvanceOrder/useListPage`, data);
  }
  /**
 * 客户预收款订单-未使用数据列表
 * @param data
 *
 */
  unUseListPage(data) {
    return this.http.post(`/bsCustomerAdvanceOrder/unUseListPage`, data);
  }

  /**
   * 编辑收款账户
   * @param data
   *
   */
  editeditProviderPayeeInfo(data) {
    return this.http.post(`/customer/provider/editProviderPayee`, data);
  }

  // 工单结算申请（异地）
  /**
 * 删除草稿
 * @param data
 *
 */
  bsOrderTaskSettWorkOrderDelete(id) {
    return this.http.post(`/bsOrderTaskSett/delete/` + id);
  }

  /**
   * 工单申请列表
   * @param data
   *
   */
  bsOrderTaskSettWorkOrderLists(data) {
    return this.http.post(`/bsOrderTaskSett/orderTaskSettList`, data);
  }

  /**
 * 获取详情
 * @param data
 *
 */
  bsOrderTaskSettWorkOrderDetail(id) {
    return this.http.post(`/bsOrderTaskSett/detail/` + id);
  }

  /**
   * 结算申请(新增)
   * @param data
   *
   */
  bsOrderTaskSettWorkOrderSet(data) {
    return this.http.post(`/bsOrderTaskSett/insertBsOrderTaskSett`, data);
  }

  /**
   * 工单结算草稿保存
   * @param data
   *
   */
  bsOrderTaskSettWorkOrderDraft(data) {
    return this.http.post(`/bsOrderTaskSett/insertDraftOrderTaskSett`, data);
  }

  /**
   * 我发起的
   * @param data
   *
   */
  bsOrderTaskSettWorkOrdeInitiatorList(data) {
    return this.http.post(`/bsOrderTaskSett/initiatorList`, data);
  }

  /**
   * 我接收的
   * @param data
   *
   */
  bsOrderTaskSettWorkOrdeAcceptList(data) {
    return this.http.post(`/bsOrderTaskSett/acceptList`, data);
  }

  /**
  * 草稿id
  * @param data
  *
  */
  bsOrderTaskSettWorkOrdeAcceptDraftDetail() {
    return this.http.post(`/bsOrderTaskSett/draftDetail`);
  }

  /**
   * 审核-申请
   * @param data
   *
   */
  bsOrderTaskSettWorkOrderCheck(data) {
    return this.http.post(`/bsOrderTaskSett/check`, data);
  }

  /**
   * 财务审核工单结算列表
   * @param data
   *
   */
  bsOrderTaskSettWorkOrderCheckList(data) {
    return this.http.post(`/bsOrderTaskSett/checkList`, data);
  }

  /**
   * 工单-保存发票信息
   * @param data
   *
   */
  workOrderTaskSettInvoice(data) {
    return this.http.post(`/bsOrderTaskSettInvoice/saveBsOrderTaskSettInvoice`, data);
  }

  /**
   * 甩单-保存发票信息
   * @param data
   *
   */
  singleOrderTaskSettInvoice(data) {
    return this.http.post(`/bsOrderSingleClearingInvoice/saveBsOrderSingleInvoice`, data);
  }

  /**
   * 甩单-通过结算id获取发票
   * @param data
   *
   */
  singleOrderTaskSettInvoicegApplyId(id) {
    return this.http.post(`/bsOrderSingleClearingInvoice/getByOrderSingleClearingApplyId/` + id);
  }

  /**
   * 工单-通过结算id获取发票
   * @param data
   *
   */
  workOrderTaskSettInvoiceApplyId(id) {
    return this.http.post(`/bsOrderTaskSettInvoice/getByOrderTaskSettId/` + id);
  }

  /**
   * 回款审核 - 查询总额笔数
   * @param data
   *
   */
  verifyOrderlistpayStatistics(data) {
    return this.http.post(`/customer/offlinePay/payStatistics`, data);
  }

  /**
   * 续费列表 - 查询
   * @param data
   *
   */
  getRenewOrderlist(data) {
    return this.http.post(`/customer/orderProduct/renewList`, data);
  }

  /**
   * 续费列表 - 保存跟进日志
   * @param data
   *
   */
  renewOrderaddLog(data) {
    return this.http.post(`/bsOrderProductRenewLog/add`, data);
  }

  /**
   * 续费列表 - 通过续费查询跟进日志
   * @param data
   *
   */
  renewOrderLogList(id) {
    return this.http.post(`/bsOrderProductRenewLog/logList/` + id);
  }

  /**
   * 续费列表 - 续费获取通过id
   * @param data
   *
   */
  renewOrderDetails(id) {
    return this.http.post(`/customer/orderProduct/get/` + id);
  }

  /**
   * 续费列表 - 续费失败
   * @param data
   *
  */
  renewOrderFailStatus(id) {
    return this.http.post(`/customer/orderProduct/renewFailStatus/` + id);
  }

  // 客户档案
  /**
   * 新增
   * @param data
   *
  */
  customerArchivesAddInfos(data) {
    return this.http.post(`/archivesCustomer/insertArchivesCustomer`, data);
  }

  /**
   * 档案附件附件列表 调取
   * @param data
   *
  */
  ArchivesFileLists(data) {
    return this.http.post(`/archivesCustomerAttachment/archivesList`, data);
  }
  /**
   * 档案附件附件列表 查看
   * @param data
   *
  */
  archivesCustomerAttachmentList(data) {
    return this.http.post(`/archivesCustomerAttachment/archivesCustomerAttachmentList`, data);
  }

  // 设置附件状态无效
  updateArchivesCustomerAttachmentStatus(data) {
    return this.http.postParams(`/archivesCustomerAttachment/updateArchivesCustomerAttachmentStatus/` + data.id);
  }

  // 附件下载
  archivesCustomerAttachmentDownload(data) {
    return this.http.post(`/archivesCustomerAttachmentDownload/download`, data);
  }

  // 附件分享
  archivesCustomerShare(data) {
    return this.http.post(`/archivesCustomerShare/shareUser`, data);
  }

  /**
   *档案附件分享列表
   * @param data
   *
  */
  archivesCustomerShareList(data) {
    return this.http.post(`/archivesCustomerShare/archivesCustomerShareList`, data);
  }

  /**
   *分享详情
   * @param data
   *
  */
  shareDetail(id) {
    return this.http.post(`/archivesCustomerShare/shareDetail/${id}`);
  }

  /**
   *档案附件下载记录
   * @param data
   *
  */
  archivesCustomerAttachmentDownloadList(data) {
    return this.http.post(`/archivesCustomerAttachmentDownload/archivesCustomerAttachmentDownloadList`, data);
  }
  /**
   *档案分享查看记录
   * @param data
   *
  */
  archivesCustomerShareUserRecordList(data) {
    return this.http.post(`/archivesCustomerShareUserRecord/archivesCustomerShareUserRecordList`, data);
  }

  // 工资模块 start

  /**
   *薪水申请列表
   * @param data
   *
  */
  getUserSalaryApplyList(data) {
    return this.http.post(`/sysUserSalaryApply/userSalaryApplyList`, data);
  }
  /**
* 删除草稿
* @param data
*
*/
  userSalaryApplyDeleteDraft(id) {
    return this.http.post(`/sysUserSalaryApply/delete/` + id);
  }
  /**
* 获取草稿详情
* @param data
*
*/
  getUserSalaryApplyDraftDetail() {
    return this.http.post(`/sysUserSalaryApply/draftDetail`);
  }

  /**
* 薪水申请详情
* @param data
*
*/
  userSalaryApplyDetail(id) {
    return this.http.post(`/sysUserSalaryApply/detail/` + id);
  }

  /**
   *保存草稿
    * @param data
    *
  */
  addDraftUserSalaryApplys(data) {
    return this.http.post(`/sysUserSalaryApply/addDraftUserSalaryApply`, data);
  }

  /**f
 *添加薪水申请
  * @param data
  *
*/
  addUserSalaryApplys(data) {
    return this.http.post(`/sysUserSalaryApply/addUserSalaryApply`, data);
  }

  /**
   *申请员工列表
    * @param data
    *
  */
  getUserEmployeesApplyList(data) {
    return this.http.post(`/sysUserInduction/employeesApplyList`, data);
  }

  /**
 *审核薪水列表
  * @param data
  *
*/
  getUsercheckSalaryList(data) {
    return this.http.post(`/sysUserSalaryApply/checkSalaryList`, data);
  }

  /**
  *申请审核
   * @param data
   *
 */
  userSysUserSalaryApplyCheck(data) {
    return this.http.post(`/sysUserSalaryApply/checkNew`, data);
  }
  /**
  *申请审核 驳回
   * @param data
   *
 */
  userSysUserSalaryApplyReject(data) {
    return this.http.post(`/sysUserSalaryApply/reject`, data);
  }

  /**
 *员工工资条列表
  * @param data
  *
*/
  getUserSalaryList(data) {
    return this.http.post(`/sysUserSalary/userSalaryList`, data);
  }

  /**
 *个人工资条列表
  * @param data
  *
*/
  myUserSalaryList(data) {
    return this.http.post(`/sysUserSalary/myUserSalaryList`, data);
  }

  /**
*员工工资条 汇总
 * @param data
 *
*/
  getUserSalaryStatistics(data) {
    return this.http.post(`/sysUserSalary/statistics`, data);
  }

  /**
 *员工工资条列表（所有）
  * @param data
  *
*/
  getUserSalaryListAll(data) {
    return this.http.post(`/sysUserSalary/userSalaryListAll`, data);
  }

  /**
 *批量保存工资条
  * @param data
  *
*/
  getUserSalaryListSave(data) {
    return this.http.post(`/sysUserSalary/saveList`, data);
  }

  // 工资模块 end

  /**
   *审核消息推送
    * @param data
    *
  */
  userExamineConfigAdd(data) {
    return this.http.post(`/sysUserPosition/add`, data);
  }
  /**
 *查询职位
  * @param data
  *
*/
  userExamineConfigFind(userId) {
    return this.http.post(`/sysUserPosition/find/` + userId);
  }

  /**
*卡位配置 批量保存
* @param data
*
*/
  saveListCard(data) {
    return this.http.post(`/sysOfficeRelation/saveList`, data);
  }
  /**
*卡位配置 获取列表
* @param data
*
*/
  getListCard() {
    return this.http.post(`/sysOfficeRelation/list`);
  }

  // 卡位每月配置列表
  getCardMonthList(data) {
    return this.http.post(`/sysOfficeMonth/monthList`, data);
  }
  // 卡位每月配置保存
  cardSaveMonth(data) {
    return this.http.post(`/sysOfficeMonth/saveMonth`, data);
  }
  // 卡位每月配置详情
  cardDetail(id) {
    return this.http.post(`/sysOfficeMonth/detail/` + id);
  }
  // 卡位每月配置删除
  cardDelete(id) {
    return this.http.post(`/sysOfficeMonth/delete/` + id);
  }

  /**
*每月卡位统计 获取列表
* @param data
*
*/
  statisticsList(data) {
    return this.http.post(`/sysOfficeCardMonth/statisticsList`, data);
  }

  /**
 *每月卡位统计 详情
 * @param data
 *
 */
  statisticsListDetail(data) {
    return this.http.post(`/sysOfficeCardStatistics/statisticsList`, data);
  }
  // 业务加职能明细
  bussFunDetail(shareMonth) {
    return this.http.post(`/sysUserApplyItem/bussFunDetail/${shareMonth}`);
  }
  // 深圳业务+职能工资工资
  wqSalary(shareMonth) {
    return this.http.post(`/sysUserApplyItem/wqSalary/${shareMonth}`);
  }
  // 业务分摊总额
  bussDetail(shareMonth) {
    return this.http.post(`/sysUserApplyItem/bussDetail/${shareMonth}`);
  }
  /**
*财务报表 - 公司年报
* @param data
*
*/
  getCompantOfficeYearList(data) {
    return this.http.post(`/sysOfficeYear/list`, data);
  }
  /**
*财务报表 - 公司年报 多个公司
* @param data
*
*/
  getCompantOfficeYearListAll(data) {
    return this.http.post(`/sysOfficeYear/listAll`, data);
  }

  /**
*财务报表 - 公司年报 组合查询
* @param data
*
*/
  getCompantOfficeYearGroupListAll(data) {
    return this.http.post(`/sysOfficeYearGroup/listAll`, data);
  }

  /**
*财务报表 - 根据组合配置id 查找公司
* @param data
*
*/
  getByReportConfigId(configId) {
    return this.http.post(`/sysOfficeReportConfigItem/getByReportConfigId/${configId}`);
  }
  /**
*财务报表 - 公司年报 查看子集
* @param data
*
*/
  getCompantOfficeYearChildListAll(data) {
    return this.http.post(`/sysOfficeYear/childListAll`, data);
  }

  /**
*财务报表 - 营业成本明细
* @param data
*
*/
  businessCostDetail(data) {
    return this.http.post(`/sysOfficeYear/businessCostDetail`, data);
  }
  /**
*财务报表 - 营业成本明细 请款退回
* @param data
*
*/
  businessCostBackDetail(data) {
    return this.http.post(`/sysOfficeYear/businessCostBackDetail`, data);
  }
  // 获取已请款退回明细
  requestRefundDetail(orderId) {
    return this.http.post(`/bsServiceProviderRequestPaymentRefundOrder/requestRefundDetail/${orderId}`);
  }
  /**
   /**
*财务报表 - 营业成本明细 推荐支出
* @param data
*
*/
  recommendOutDetail(data) {
    return this.http.post(`/sysOfficeYear/recommendOutDetail`, data);
  }
  /**
*财务报表 - 营业回款明细 收付实现制
* @param data
*
*/
  collectionDetail(data) {
    return this.http.post(`/sysOfficeYear/collectionDetail`, data);
  }
  /**
*财务报表 - 营业回款明细 权责发生制
* @param data
*
*/
  collectionDetailFull(data) {
    return this.http.post(`/sysOfficeYear/collectionDetailFull`, data);
  }
  /**
*财务报表 - 营业回款明细 - 推荐收入
* @param data
*
*/
  recommendInDetail(data) {
    return this.http.post(`/sysOfficeYear/recommendInDetail`, data);
  }
  /**
*财务报表 - 退款明细
* @param data
*
*/
  refundDetail(data) {
    return this.http.post(`/sysOfficeYear/refundDetail`, data);
  }
  /**
*财务报表 - 交付收入明细
* @param data
*
*/
  deliveryRevenueDetail(data) {
    return this.http.post(`/sysOfficeYear/deliveryRevenueDetail`, data);
  }
  /**
*财务报表 - 交付成本明细
* @param data
*
*/
  deliveryCostDetail(data) {
    return this.http.post(`/sysOfficeYear/deliveryCostDetail`, data);
  }
  /**
*财务报表 - 营销费用明细
* @param data
*
*/
  unBusinessCostDetail(data) {
    return this.http.post(`/sysOfficeYear/unBusinessCostDetail`, data);
  }
  /**
*财务报表 - 人员薪酬成本明细
* @param data
*
*/
  salaryCostDetail(data) {
    return this.http.post(`/sysOfficeYear/salaryCostDetail`, data);
  }
  /**
*财务报表 - 人员薪酬奖金成本明细
* @param data
*
*/
  salaryBonusCostDetail(data) {
    return this.http.post(`/sysOfficeYear/salaryBonusCostDetail`, data);
  }

  /**
*财务报表 - 其他费用明细
* @param data
*
*/
  otherCostDetail(data) {
    return this.http.post(`/sysOfficeYear/otherCostDetail`, data);
  }

  /**
*预扣交付成本 - 预扣交付成本明细
* @param data
*
*/
  withholdingDeliveryCostDetail(data) {
    return this.http.post(`/sysOfficeYear/withholdingDeliveryCostDetail`, data);
  }

  /**
*预扣交付成本 - 反还交付成本
* @param data
*
*/
  withholdingDeliveryFhCostDetail(data) {
    return this.http.post(`/sysOfficeYear/withholdingDeliveryFhCostDetail`, data);
  }

  /**
*财务报表 - 批量修改
* @param data
*
*/
  CompantOfficeYearUpdate(data) {
    return this.http.post(`/sysOfficeYear/updateList`, data);
  }

  // 获取 股权穿透信息
  getCompanyInvestLists(id) {
    return this.http.get(`/bsCompanyInvest/getCompanyInvestList/${id}`);
  }

  /**

*客户申请审核列表
* @param data
*
*/
  customerApplyCheckLists(data) {
    return this.http.post(`/bsCustomerApply/customerApplyCheckList`, data);
  }

  /**
  *客户申请审核列表 - 审核
  * @param data
  *
  */
  customerApplyChecks(data) {
    return this.http.post(`/bsCustomerApply/check`, data);
  }

  /*查询客户数量
  * @param data
  *
  */
  customerCount(data) {
    return this.http.post(`/bsCustomerApply/customerCount`, data);
  }
  /**
*提交申请客户
* @param data
*
*/
  customerSaveInfo(data) {
    return this.http.post(`/bsCustomerApply/saveInfo`, data);
  }
  /**
*我的客户申请列表
* @param data
*
*/
  customerApplyList(data) {
    return this.http.post(`/bsCustomerApply/customerApplyList`, data);
  }

  /**
*客户申请详情 
* @param data
*
*/
  customerApplyItemLists(data) {
    return this.http.post(`/bsCustomerApplyItem/customerApplyItemList`, data);
  }

  // 获取 客户申请详情 - 参数列表
  getcustomerApplyParamList(id) {
    return this.http.post(`/bsCustomerApplyParam/customerApplyParamList/${id}`);
  }

  // 获取 客户申请详情 - 添加客户跟进记录
  addCustomerJobRecords(data) {
    return this.http.post(`/bsCustomerApplyItem/addCustomerJobRecord`, data);
  }

  // 分享用户
  saveCustomerUser(data) {
    return this.http.post(`/bsCustomerApply/saveUser`, data);
  }

  // 分享的客户
  shareCustomerApplyCheckLists(data) {
    return this.http.post(`/bsCustomerApply/shareCustomerApplyCheckList`, data);
  }

  // 经纬创新 - 客户订单列表
  getJwcxOrderLists(data) {
    return this.http.post(`/dkOrder/orderList`, data);
  }

  // 经纬创新 - 产品列表
  getJwcxProductList(data) {
    return this.http.post(`/dkProduct/productList`, data);
  }

  // 经纬创新 - 修改产品
  getJwcxProductUpdate(data) {
    return this.http.post(`/dkProduct/update`, data);
  }

  // 获取 客户申请详情 - 同步订单
  getJwcxOrderInfo(id) {
    return this.http.post(`/dkOrder/sync/${id}`);
  }

  // 设置区域主管审核是否开启0否1是
  setOfficeConfigAreaManageFlag(data) {
    return this.http.post(`/system/sysOfficeConfig/setAreaManageFlag`, data);
  }

  // 创建服务
  addCompanyServers(data) {
    return this.http.post(`/bsCompanyServerItem/add`, data);
  }

  // 获取产品服务信息
  serverProductDetail(id) {
    return this.http.post(`/customer/orderProduct/detail/${id}`);
  }

  // 订单产品关联的服务类型
  getServerType(id) {
    return this.http.post(`/bsOrderProductRecordType/getServerType/${id}`);
  }

  // 企业服务类型列表数据
  getCompanyServerTypeList() {
    return this.http.get(`/bsCompanyServerType/listData`);
  }

  // 服务列表
  getCompanyServersList(data) {
    return this.http.post(`/bsCompanyServer/serverList`, data);
  }
  // 续费管理 所有
  getSendServerListRenew(data) {
    return this.http.post(`//bsCompanyServerInfo/teamSendServerList`, data);
  }
  // 续费管理 个人
  getSendServerListRenewSingle(data) {
    return this.http.post(`//bsCompanyServerInfo/xfServerList`, data);
  }
  // 续费详情
  bsCompanyServerInfoGet(id) {
    return this.http.post(`/bsCompanyServerInfo/get/${id}`);
  }
  // 团队服务
  getTeamSendServerList(data) {
    return this.http.post(`/bsCompanyServer/teamSendServerList`, data);
  }
  // 团队待办
  getTeamChargeServerList(data) {
    return this.http.post(`/bsCompanyServer/teamChargeServerList`, data);
  }

  // 负责人服务列表
  getChargeServerListt(data) {
    return this.http.post(`/bsCompanyServer/chargeServerList`, data);
  }

  // 发起人服务列表
  getSendServerList(data) {
    return this.http.post(`/bsCompanyServer/sendServerList`, data);
  }

  // 抄送服务列表
  getCopyServerList(data) {
    return this.http.post(`/bsCompanyServer/copyServerList`, data);
  }

  // 服务列表  待分配列表
  distributionServerList(data) {
    return this.http.post(`/bsCompanyServer/distributionServerList`, data);
  }

  // 查询企业服务是否存在
  findByCompanyIdAndServerType(data) {
    return this.http.post(`/bsCompanyServer/findByCompanyIdAndServerTypeId`, data);
  }

  // 续费服务
  renewCompanyIdAndServer(data) {
    return this.http.post(`/bsCompanyServerItem/renew`, data);
  }

  // 服务期间列表
  getCompanyServerPeriod(data) {
    return this.http.post(`/bsCompanyServerPeriod/serverPeriodList`, data);
  }
  // 服务详情
  getServerDetail(id) {
    return this.http.post(`/bsCompanyServer/get/${id}`);
  }

  // 服务详情 新
  getServerDetailNew(id) {
    return this.http.post(`/bsCompanyServer/getServer/${id}`);
  }


  // 添加跟进
  addCompanyServerPeriodFollow(data) {
    return this.http.post(`/bsCompanyServerPeriodFollow/add`, data);
  }

  // 操作记录
  getCompanyServerLog(data) {
    return this.http.post(`/bsCompanyServerLog/logList`, data);
  }

  // 完成交付
  completeCompanyServer(data) {
    return this.http.post(`/bsCompanyServerPeriod/complete`, data);
  }
  // 重新开启
  reOpenCompanyServer(data) {
    return this.http.post(`//bsCompanyServer/reOpen`, data);
  }

  // 长期
  // 异常
  companyServerUnusual(data) {
    return this.http.post(`/bsCompanyServerPeriod/unusual`, data);
  }
  // 解除异常
  companyServerUnUnusual(data) {
    return this.http.post(`/bsCompanyServerPeriod/unUnusual`, data);
  }

  // 一次性
  // 异常
  companyServerOnceUnusual(data) {
    return this.http.post(`/bsCompanyServer/unusual`, data);
  }
  // 解除异常
  companyServerOnceUnUnusual(data) {
    return this.http.post(`/bsCompanyServer/unUnusual`, data);
  }

  // 添加负责人
  serverAddUser(data) {
    return this.http.post(`/bsCompanyServer/addUser`, data);
  }
  // 开启服务
  serverOpen(data) {
    return this.http.post(`/bsCompanyServer/open`, data);
  }
  // 更改服务状态
  serverUpdate(data) {
    return this.http.post(`/bsCompanyServer/updateStatus`, data);
  }

  // 修改财税顾问
  updateUserCsgw(data) {
    return this.http.post(`/bsCompanyServerInfo/updateUser`, data);
  }
  // 修改服务时间
  updateTime(data) {
    return this.http.post(`/bsCompanyServerInfo/updateTime`, data);
  }

  // 一次性服务产品
  // 跟进记录
  getCompanyServerFollowList(data) {
    return this.http.post(`/bsCompanyServerPeriodFollow/followList`, data);
  }

  /***
   * 任务管理模块
   */
  // 类型列表
  getTaskTypeLists() {
    return this.http.post(`/taskType/taskTypes`);
  }

  // 添加类型 
  addTaskTypes(data) {
    return this.http.post(`/taskType/addTaskType`, data);
  }
  //编辑
  updateTaskTypes(data) {
    return this.http.post(`/taskType/updateTaskType`, data);
  }

  // 类型用户列表（分页） 
  getTaskTypeUserPageList(data) {
    return this.http.post(`/taskTypeUser/taskTypeUserPageList`, data);
  }

  // 删除类型用户
  deleteTaskTypeListUser(id) {
    return this.http.post(`/taskTypeUser/del/${id}`);
  }

  //任务列表
  getTaskInfoPageList(data) {
    return this.http.post(`/taskInfo/taskInfoPageList`, data);
  }
  // 任务详情
  getTaskInfoDetail(id) {
    return this.http.post(`/taskInfo/taskInfoDetail/${id}`);
  }

  //任务管理列表统计
  getCountTaskInfo(data) {
    return this.http.post(`/taskInfo/countMangeTaskInfo`, data);
  }

  // 手动终止任务
  stopTaskTypeListUser(id) {
    return this.http.post(`/taskInfo/stop/${id}`);
  }

  //动态列表
  getTaskInfoLogList(data) {
    return this.http.post(`/taskInfoLog/taskInfoLogList`, data);
  }

  //参与的用户
  getTaskInfoJoinList(data) {
    return this.http.post(`/taskInfoItem/joinList`, data);
  }

  // 获取明细 绩效明细
  getUserAchievementsConfigItem(id) {
    return this.http.post(`/sysUserAchievementsConfigItem/getList/${id}`);
  }

  //保存绩效配置明细
  addUserAchievementsConfigItem(data) {
    return this.http.post(`/sysUserAchievementsConfigItem/saveConfig`, data);
  }

  // 业务销售提成列表
  getUserCommissionBusinessList(id) {
    return this.http.post(`/sys/settlement/businessList/${id}`);
  }

  // 记账报税提成列表
  getUserCommissionAccountList(id) {
    return this.http.post(`/sys/settlement/accountList/${id}`);
  }

  // 工商交付提成列表
  getUserCommissionCirclesList(id) {
    return this.http.post(`/sys/settlement/gsList/${id}`);
  }

  // 管理及其他提成列表
  getUserCommissionMnageList(id) {
    return this.http.post(`/sys/settlement/manageList/${id}`);
  }
  //批量导入 工资条信息
  importBsPayslip(data) {
    return this.http.upload(data, `/sysUserSalary/import`);
  }

  //导入 员工信息
  importUserInduction(data) {
    return this.http.upload(data, `/sysUserInduction/import`);
  }
  // 删除工资条
  delSalary(id) {
    return this.http.post(`/sysUserSalary/del/${id}`);
  }
  //查询 组织架构 信息
  getUserOfficeDeptInfo(id) {
    return this.http.post(`/sys/office/get/${id}`);
  }
  // 退款追回列表
  getUserCommissionRefundList(id) {
    return this.http.post(`/sys/settlement/refundList/${id}`);
  }
  // 删除配置项
  userAchievementsConfigItemDelete(id) {
    return this.http.post(`/sysUserAchievementsConfigItem/delete/${id}`);
  }

  // 报销明细 添加附件
  reimbursementsApplySaveFileurl(data) {
    return this.http.post(`/sysUserApplyItemAttachment/save`, data);
  }

  // 日志模板  保存或修改模板
  addUserJournalTemplate(data) {
    return this.http.post(`/customerLogTemplate/save`, data);
  }

  // 日志模板  模板列表（分页）
  getUserJournalTemplateLists(data) {
    return this.http.post(`/customerLogTemplate/templateList`, data);
  }

  // 日志模板  删除模板
  userJournalTemplateListDelete(id) {
    return this.http.post(`/customerLogTemplate/delete/${id}`);
  }

  // 日志模板  模板明细
  userJournalTemplateListDetails(id) {
    return this.http.post(`/customerLogTemplate/detail/${id}`);
  }

  // 我的日志 列表（分页）
  getUserCustomerLogList(data) {
    return this.http.post(`/customerLog/myCustomerLogList`, data);
  }

  // 团队日志 列表（分页）
  getTeamCustomerLogList(data) {
    return this.http.post(`/customerLog/customerLogList`, data);
  }
  // 日志删除
  userCustomerLogListDelete(id) {
    return this.http.post(`/customerLog/delete/${id}`);
  }
  // 保存日志
  saveUserCustomerLog(data) {
    return this.http.post(`/customerLog/saveCustomerLog`, data);
  }

  // 获取公司下的客户
  getCompanyCustmerLists(companyId) {
    return this.http.post(`/customer/bsCompany/getCustomerList/${companyId}`);
  }

  // 客户日志
  getUserCustomerLogLists(data) {
    return this.http.post(`/customerLog/customerList`, data);
  }

  // 查询客户 公司信息 （姓名，手机，微信号）
  queryCustomerInfo(id) {
    return this.http.post(`/customer/customerInfo/verificationContact/${id}`);
  }

  //订单价格审核
  orderPriceExamineCheck(data) {
    // checkOrder
    return this.http.post(`/customer/order/checkOrderNew`, data);
  }

  //发起订单价格审核
  setOrderPriceExamineCheck(data) {
    // return this.http.post(`/customer/order/commitOrderCheck/${id}`);
    return this.http.post(`/customer/order/commitOrderCheckNew`, data);
  }

  //订单办结
  userOrderCommitFinish(data) {
    // return this.http.post(`/customer/order/commitFinish/${id}`);
    return this.http.post(`/customer/order/commitFinishNew`, data);
  }
  //订单办结审核
  ordercheckOrderFinishCheck(data) {
    // return this.http.post(`/customer/order/checkOrderFinish`, data);
    return this.http.post(`/customer/order/checkOrderFinishNew`, data);
  }
  // 查询我的产品列表
  getMyOrderProduct(data) {
    return this.http.post(`/customer/orderProduct/list`, data);
  }
  //产品办结提交
  userProductCommitFinish(data) {
    return this.http.post(`/customer/orderProduct/commitFinish`, data);
  }
  //产品办结审核列表
  getProductexamine(data) {
    return this.http.post(`//customer/orderProduct/checkList`, data);
  }
  //产品办结审核
  productCheckOrderFinishCheck(data) {
    return this.http.post(`/customer/orderProduct/checkOrderProductFinish`, data);
  }
  //根据订单产品ID，查询详情
  orderProductInfo(id) {
    return this.http.post(`/customer/orderProduct/detailInfo/${id}`,);
  }

  // 企查猫查询公司名称
  orgCompanyFindByQzyCompanyName(data) {
    return this.http.post(`/orgCompany/findByQzyCompanyName`, data);
  }

  // 工单交付审核任务列表
  workCompleteExamineList(data) {
    return this.http.post(`/customer/orderTask/checkList`, data);
  }

  // 验收审核
  workCompleteExamineListCheck(data) {
    // checkOrderTaskAcceptance 旧审核
    return this.http.post(`/bsOrderTaskCheck/checkOrderTaskAcceptanceNew`, data);
  }

  // 我的流失客户
  myCustomerAwayList(data) {
    return this.http.post(`/bsCustomerAway/myCustomerAwayList`, data);
  }
  // 流失客户列表
  customerAwayList(data) {
    return this.http.post(`/bsCustomerAway/customerAwayList`, data);
  }
  // 保存流失客户
  saveCustomerAway(data) {
    return this.http.post(`/bsCustomerAway/saveCustomerAway`, data);
  }
  // 业务主管审核
  saveMangeCheck(data) {
    return this.http.post(`/bsCustomerAway/saveMangeCheck`, data);
  }
  // 分发到交付人员
  saveDistributeCheck(data) {
    return this.http.post(`/bsCustomerAway/saveDistributeCheck`, data);
  }
  // 交付主管审核
  saveDeliverMangeCheck(data) {
    return this.http.post(`/bsCustomerAway/saveDeliverMangeCheck`, data);
  }
  // 交付
  saveDeliverCheck(data) {
    return this.http.post(`/bsCustomerAway/saveDeliverCheck`, data);
  }
  // 城市经理审核
  saveCityCheck(data) {
    return this.http.post(`/bsCustomerAway/saveCityCheck`, data);
  }

  // 流失审核新接口
  customerLossCheckNew(data) {
    return this.http.post(`/bsCustomerAway/checkNew`, data);
  }
  // 获取流失客户详情
  customerAwayDetail(id) {
    return this.http.post(`/bsCustomerAway/detail/${id}`);
  }

  // 企业信息导入（领取企业客户）
  // 获取企业信息列表
  enterCompanyList(data) {
    return this.http.post(`/bsEnterprise/companyList`, data);
  }

  // 工商部线索池
  enterCompanyList2(data) {
    return this.http.post(`/bsEnterprise/companyList2`, data);
  }

  /**
   *
   * @param data 导入企业信息
   */
  enterprImportCompany(data) {
    // console.log(this.http.upload)
    return this.http.upload(data, `/bsEnterprise/import`);
  }

  /**
   *
   * @param data 工商部线索池 导入企业信息
   */
  enterprImportCompany2(data) {
    // console.log(this.http.upload)
    return this.http.upload(data, `/bsEnterprise/import2`);
  }

  // 领取企业
  receiveCompany(id) {
    return this.http.post(`/bsEnterprise/receive/${id}`);
  }

  //系统版本
  /**
   * 版本数据列表
   */
  sysVersionList(data) {
    return this.http.post(`/sysVersion/list`, data);
  }

  // 删除版本数据列表
  sysVersionDel(id) {
    return this.http.post(`/sysVersion/delete/${id}`);
  }

  // 添加版本数据列表
  sysVersionSave(data) {
    return this.http.post(`/sysVersion/saveOrUpdate`, data);
  }

  // 技术工单列表
  getAdminWorkOrderListAll(data) {
    return this.http.post(`/bsWorkOrder/workOrderListAll`, data);
  }

  // 技术工单 接单
  getAdminWorkOrderListReceive(id) {
    return this.http.post(`/bsWorkOrder/receive/${id}`);
  }

  // 技术工单 详情
  getAdminWorkOrderListDetail(id) {
    return this.http.post(`/bsWorkOrder/detail/${id}`);
  }

  // 技术工单 处理方回复
  saveReceiveWorkOrderConnects(data) {
    return this.http.post(`/bsWorkOrderConnect/saveReceiveWorkOrderConnect`, data);
  }

  // 技术工单 结单
  getAdminWorkOrderListStatement(id) {
    return this.http.post(`/bsWorkOrder/statement/${id}`);
  }

  // 团队订单 汇总
  teamOrderPriceAll(data) {
    return this.http.post(`/customer/order/orderSum`, data);
  }

  // 企业服务类型列表数据
  getCompanyServerTypeListData() {
    return this.http.get(`/bsCompanyServerType/listData`);
  }
  // 企业服务类型列表数据 删除
  getCompanyServerTypeListDelete(id) {
    return this.http.post(`/bsCompanyServerType/delete/${id}`);
  }
  // 添加服务类型
  saveOrUpdateCompanyServerType(data) {
    return this.http.post(`/bsCompanyServerType/saveOrUpdate`, data);
  }
  // 企业服务类型明细
  getCompanyServerItemList(data) {
    return this.http.post(`/bsCompanyServerTypeItem/itemList`, data);
  }
  // 企业服务类型明细 不分页
  getCompanyServerItemListAll() {
    return this.http.post(`/bsCompanyServerTypeItem/itemListAll`, {});
  }
  // 企业服务类型明细 删除
  getCompanyServerTypeListDeleteItem(id) {
    return this.http.post(`/bsCompanyServerTypeItem/deleteItem/${id}`);
  }
  // 添加服务类型 明细
  saveItem(data) {
    return this.http.post(`/bsCompanyServerTypeItem/saveItem`, data);
  }
  // 企业服务产品列表
  serverProductList(id) {
    return this.http.post(`/bsCompanyServerInfo/productList/${id}`);
  }
  /**
  * 企业服务 干系人角色
  * @param data
  */
  getServerRole() {
    return this.http.get('/bsCompanyServerRole/listData');
  }

  // 企业服务 干系人 批量保存
  saveOrUpdateBatch(data, companyServerId) {
    return this.http.post(`/bsCompanyServerUser/saveOrUpdateBatch/${companyServerId}`, data);
  }

  // 企业服务 干系人 查询详情
  serverUserDetail(companyServerId) {
    return this.http.get(`/bsCompanyServerUser/listData/${companyServerId}`);
  }

  // 批量保存服务
  saveBatchServers(data) {
    return this.http.post(`/bsCompanyServerItem/saveBatchServer`, data);
  }
  // 保存企业服务 （新）
  saveBatchServersNew(data) {
    return this.http.post(`/bsCompanyServerInfo/saveBatchServer`, data);
  }
  // 交付列表
  deliverPeriodList(data) {
    return this.http.post(`/bsCompanyServerPeriod/deliverPeriodList`, data);
  }
  // 交付列表2 按年
  deliverPeriodList2(data) {
    return this.http.post(`/bsCompanyServerPeriod/deliverPeriodList2`, data);
  }
  // 批量交付
  batchDeliver(data) {
    return this.http.post(`/bsCompanyServerPeriod/batchDeliver`, data);
  }
  // 批量交付2
  batchDeliver2(data) {
    return this.http.post(`/bsCompanyServerPeriod/batchDeliver2`, data);
  }
  // 批量交付 按年
  batchDeliverYear(data) {
    return this.http.post(`/bsCompanyServerDeliverRecord/batchDeliver`, data);
  }
  // 交付记录
  deliverList(data) {
    return this.http.post(`/bsCompanyServerDeliverRecord/deliverList`, data);
  }
  // 取消交付记录
  deliverCancel(id) {
    return this.http.post(`//bsCompanyServerDeliverRecord/cancel/${id}`);
  }
  // 交付记录详情
  deliverDetail(id) {
    return this.http.post(`/bsCompanyServerDeliverRecord/detail/${id}`);
  }
  // 服务终止
  bsCompanyServerStop(data) {
    return this.http.post(`/bsCompanyServer/stop`, data);
  }

  // 我的交付记录汇总列表
  deliverMySumList(data) {
    return this.http.post(`/bsCompanyServerDeliverSummary/mySumList`, data);
  }
  // 团队交付记录汇总列表
  deliverTeamSumList(data) {
    return this.http.post(`/bsCompanyServerDeliverSummary/teamSumList`, data);
  }

  // 团队交付记录汇总列表详情
  deliverTeamDetail(data) {
    return this.http.post(`/bsCompanyServerDeliver/detailList`, data);
  }

  // 交付记录汇总列表 详情
  deliverTotalDetail(id) {
    return this.http.post(`/bsCompanyServerDeliverSummary/detail/${id}`);
  }

  // 查询企业服务是否存在
  findIsCompanyServerType(data) {
    return this.http.post(`/bsCompanyServer/findByCompanyIdAndServerTypeId`, data);
  }
  // 查询企业服务是否存在 新
  findIsCompanyServerTypeNew(data) {
    return this.http.post(`//bsCompanyServerInfo/findByCompanyIdAndCateId`, data);
  }
  // 查询客户服务是否存在
  findIsCustomerServerType(data) {
    return this.http.post(`/bsCompanyServer/findByCustomerIdAndServerTypeId`, data);
  }

  // 工单查询 服务明细
  findWorkServerItems(data) {
    return this.http.post(`/bsCompanyServerItem/serverItemList`, data);
  }
  // 企业下的服务列表
  findCompanyServerItems(data) {
    return this.http.post(`/bsCompanyServer/companyServerList`, data);
  }
  // 获取结束时间
  getServerEndDate(data) {
    return this.http.post(`/bsCompanyServer/getEndDate`, data);
  }

  // 设置城市经理 保存
  saveCityMange(officeId, data) {
    return this.http.post(`/sysOfficeMange/add/${officeId}`, data);
  }
  // 设置城市经理 获取数据
  getCityMange(officeId) {
    return this.http.post(`/sysOfficeMange/getByOfficeId/${officeId}`);
  }

  // 个人额外提成 - 列表数据
  getSysUserSettlementConfig(data) {
    return this.http.post(`/sysUserSettlementConfig/listData`, data);
  }

  // 个人额外提成 - 保存
  saveUserSettlementConfigUpdate(data) {
    return this.http.post(`/sysUserSettlementConfig/saveOrUpdate`, data);
  }

  // 个人额外提成 - 删除
  saveUserSettlementConfigDelete(id) {
    return this.http.get(`/sysUserSettlementConfig/delete/${id}`);
  }
  // 检查请款金额
  paymentInspectRequestAmount(data) {
    return this.http.post(`/sys/request/payment/inspectRequestAmount`, data);
  }

  // 列表数据
  getSysOfficeSettlement(data) {
    return this.http.post(`/sysOfficeSettlement/listData`, data);
  }


  // 产品额外提成
  getProductConfig(data) {
    return this.http.post(`/sysUserSettlementProductConfig/listData`, data);
  }
  // 产品额外提成 保存
  saveProductConfig(data) {
    return this.http.post(`/sysUserSettlementProductConfig/saveOrUpdate`, data);
  }
  // 产品额外提成 删除
  delProductConfig(id) {
    return this.http.get(`/sysUserSettlementProductConfig/delete/${id}`);
  }
  // 产品额外提成 明细
  getProductConfigDetail(id) {
    return this.http.get(`/sysUserSettlementProductConfig/details/${id}`);
  }

  // 提成明细 - 订单 产品列表
  commissionProductLists(data) {
    return this.http.post(`/sysUserSettlementItemBusinessProduct/listData`, data);
  }

  // 公司会计提成配置 列表
  officeAccountList(data) {
    return this.http.post(`/sysOfficeAccountConfig/listData`, data);
  }
  // 公司会计提成配置 保存
  officeAccountSave(data) {
    return this.http.post(`/sysOfficeAccountConfig/saveOrUpdate`, data);
  }
  // 公司会计提成配置 删除
  officeAccountDel(id) {
    return this.http.get(`/sysOfficeAccountConfig/delete/${id}`);
  }

  // 会计提成明细
  getServerPeriodDetails(data) {
    return this.http.post(`/sysUserSettlementItemAccountItem/accountItemList`, data);
  }
  // 发起支付 订单抵扣
  // 通过订单编号查询
  getOrderId(orderNo) {
    return this.http.post(`/customer/order/getByOrderNo/${orderNo}`);
  }
  // 抵扣的产品列表
  deductionProductList(id) {
    return this.http.post(`/bsPayRecordOrderProduct/deductionProductList/${id}`);
  }

  // 取消订单
  customerCancelOrder(id) {
    return this.http.post(`/customer/order/cancel/${id}`);
  }

  // 删除订单
  customerDelOrder(id) {
    return this.http.post(`/customer/order/del/${id}`);
  }

  // 获取关联工单
  getBsOrderTaskList(id) {
    return this.http.post(`/customer/orderTask/bsOrderTaskList/${id}`);
  }

  // 毛利提成配置
  getSysUserGrossConfig(data) {
    return this.http.post(`/sysUserGrossConfig/listData`, data);
  }

  // 毛利提成配置 保存
  saveSysUserGrossConfig(data) {
    return this.http.post(`/sysUserGrossConfig/saveSysUserGrossConfig`, data);
  }
  // 毛利提成配置 明细
  grossConfigDetail(id) {
    return this.http.get(`/sysUserGrossConfig/details/${id}`);
  }
  // 毛利提成配置 删除
  grossConfigDel(id) {
    return this.http.get(`/sysUserGrossConfig/delete/${id}`);
  }

  // 更新毛利 保存
  updateSysUserSettlementGross(data) {
    return this.http.post(`/sysUserSettlementGross/updateSysUserSettlementGross`, data);
  }

  // 计算毛利 核对
  calculationSysUserSettlementGross(data) {
    return this.http.post(`/sysUserSettlementGross/calculationSysUserSettlementGross`, data);
  }


  // 获取毛利额外提成
  findByUserSettlementId(id) {
    return this.http.post(`/sysUserSettlementGross/findByUserSettlementId/${id}`);
  }

  // 获取服务流程
  getServiceFlowList(serviceNo) {
    return this.http.post(`/flowServiceStep/getServiceFlowList/${serviceNo}`);
  }

  // 审核列表 
  // 我待办得
  checkNeedList(data) {
    return this.http.post(`/sysUserCheck/needList`, data);
  }
  // 我申请的
  checkApplyList(data) {
    return this.http.post(`/sysUserCheck/applyList`, data);
  }
  // 我完成的
  checkHandleList(data) {
    return this.http.post(`/sysUserCheck/handleList`, data);
  }

  // 汇总
  checkSummary(data) {
    return this.http.post(`/sysUserCheck/summary`, data);
  }

  // 客户交接
  // 获取客户列表
  getHandCustomerList(data) {
    return this.http.post(`/bsCustomerHandover/customerList`, data);
  }
  // 保存交接
  saveHandover(data) {
    return this.http.post(`/bsCustomerHandover/saveHandover`, data);
  }
  // 保存交接 人与人全部交接
  savePersonHandover(data) {
    return this.http.post(`/bsCustomerHandover/savePersonHandover`, data);
  }

  // 其他收入列表
  otherIncomeList(data) {
    return this.http.post(`/bsOtherIncome/otherIncomeList`, data);
  }


  // 其他收入新增
  addBsOtherIncome(data) {
    return this.http.post(`/bsOtherIncome/addBsOtherIncome`, data);
  }
  // 其他收入删除
  delBsOtherIncome(id) {
    return this.http.post(`/bsOtherIncome/delete/${id}`);
  }
  // 其他收入 明细
  detailBsOtherIncome(id) {
    return this.http.post(`/bsOtherIncome/detail/${id}`);
  }
  // 报表配置列表
  reportConfigList(data) {
    return this.http.post(`/sysOfficeReportConfig/reportConfigList`, data);
  }
  // 报表配置列表 全部
  reportConfigListAll() {
    return this.http.post(`/sysOfficeReportConfig/reportConfigListAll`);
  }
  // 报表配置-保存
  addSreportConfigList(data) {
    return this.http.post(`/sysOfficeReportConfig/addSysOfficeReportConfig`, data);
  }
  // 报表配置-删除
  delSreportConfigList(id) {
    return this.http.post(`/sysOfficeReportConfig/delete/${id}`);
  }
  // 企业服务
  // 获取员工企业服务列表
  handoverServerList(data) {
    return this.http.post(`/bsCompanyHandover/serverList`, data);
  }
  // 获取员工企业服务列表 新
  handoverServerInfoList(data) {
    return this.http.post(`/bsCompanyHandover/serverInfoList`, data);
  }
  // 保存交接
  serveSaveHandover(data) {
    return this.http.post(`/bsCompanyHandover/saveHandover`, data);
  }

  // 用户业绩配置表 
  // 公司列表
  performanceOfficeList(data) {
    return this.http.post(`/sysUserPerformanceConfig/officeList`, data);
  }
  // 员工列表
  performanceEmployeesList(data) {
    return this.http.post(`/sysUserPerformanceConfig/employeesList`, data);
  }
  // 保存业绩配置列表
  performanceSaveList(data) {
    return this.http.post(`/sysUserPerformanceConfig/saveList`, data);
  }
  // 业绩配置列表
  performanceConfigList(data) {
    return this.http.post(`/sysUserPerformanceConfig/configList`, data);
  }
  // 用户级别
  sysUserLevel() {
    return this.http.post(`/sysUserLevel/list`);
  }
  // 目标统计表 刷新
  sysOfficeCardMonthRefresh() {
    return this.http.post(`/sysOfficeCardMonth/refresh`,);
  }
  // 目标统计表
  userCompanyTargetReport(data) {
    return this.http.post(`/sysUserCompanyStatistics/statisticsList`, data);
  }
  // 目标统计表 详情 分页
  targetReportDetail(data) {
    return this.http.post(`/sysUserStatistics/statisticsList`, data);
  }
  // 目标统计表 详情 不分页
  targetReportDetailAll(data) {
    return this.http.post(`/sysUserStatistics/statisticsListAll`, data);
  }
  // 团队汇总统计
  teamStatisticsReport(data) {
    return this.http.post(`/sysUserTeamStatistics/statisticsList`, data);
  }

  // 获取工单详情
  orderTaskDetail(orderTaskId) {
    return this.http.post(`/customer/orderTask/detail/${orderTaskId}`);
  }
  // 注销公司
  compAddLogOut(data) {
    return this.http.post(`/bsCompanyLogout/addLogOut`, data);
  }
  // 获取订单已请款明细
  requestDetail(orderId) {
    return this.http.post(`/sys/request/payment/requestDetail/${orderId}`);
  }
  // 获取订单的收款公司
  getCompany(orderId) {
    return this.http.post(`/customer/order/get/${orderId}`);
  }

  // 修改入账公司
  updateBussCompanyList(data) {
    return this.http.post(`/customer/order/updateCompany`, data);
  }

  // 找回密码
  pwdBack(data) {
    return this.http.post(`/user/pwdBack`, data);
  }

  /**
   * @description 获取手机验证码
   */
  getIphoneCode(data) {
    return this.http.postParams(`/sys/user/getIphoneCode`, data);
  }
  /**
   * @description 申请撤回
   * 审核类型0合同1退款2报销3请款4核销5加盟商充值6工资7提成8客户申请9服务商入库10订单价格审核
   * 0 CONTRACT, 1 REFUNDS, 2 APPLY, 3 REQUEST_PAYMENT, 4 APPLY_VERIFICATION, 5 ACCOUNT_PAY, 6 USER_SALARY, 7 USER_SETTLEMENT, 8 CUSTOMER_APPLY, 9 SERVICE_STORAGE, 10 ORDER_PRICE, 11 ORDER_OVER, 12 ORDER_COLLECTION, TASK_DELIVER
   */
  checkRevoke(data) {
    return this.http.post(`/sysUserCheck/revoke`, data);
  }

  // 保存规则
  saveInfoRules(data) {
    return this.http.post(`/workReportRuleUser/saveInfo`, data);
  }
  // 获取规则
  getRule() {
    return this.http.post(`/workReportRuleUser/getRule`);
  }
  // 保存规则用户
  saveRuleUser(data) {
    return this.http.post(`/workReportRuleUser/saveUserList`, data);
  }
  // 获取规则用户
  getRuleUser(data) {
    return this.http.post(`/workReportRuleUser/ruleUserAllList`, data);
  }
  // 我的汇报
  reportUserList(data) {
    return this.http.post(`/workReportUser/reportUserList`, data);
  }
  // 提交我的汇报
  saveInforeportUser(data) {
    return this.http.post(`/workReportUser/saveInfo`, data);
  }
  // 获得汇报详情
  ruleUserDetail(id) {
    return this.http.post(`/workReportUser/get/${id}`);
  }
  // 获得汇报详情 浏览汇报
  ruleUserDetailRead(id) {
    return this.http.post(`/workReportUser/read/${id}`);
  }
  // 我的浏览记录
  myReportUserReadList(data) {
    return this.http.post(`/workReportUserRead/myReportUserReadList`, data);
  }
  // 汇报总表
  reportAllList(data) {
    return this.http.post(`/workReport/reportAllList`, data);
  }
  // 应汇报
  reportUserAllList(data) {
    return this.http.post(`/workReportUser/reportUserAllList`, data);
  }
  // 已汇报
  reportedUserList(data) {
    return this.http.post(`/workReportUser/reportedUserList`, data);
  }
  // 未汇报
  unReportUserList(data) {
    return this.http.post(`/workReportUser/unReportUserList`, data);
  }
  // 评分
  saveUserScore(data) {
    return this.http.post(`/workReportUserScore/saveUserScore`, data);
  }
  // 我的评分列表
  myReportUserScoreList(data) {
    return this.http.post(`/workReportUserScore/myReportUserScoreList`, data);
  }
  // 评分明细列表
  reportUserScoreList(data) {
    return this.http.post(`/workReportUserScore/reportUserScoreList`, data);
  }
  // 浏览明细列表
  reportUserReadList(data) {
    return this.http.post(`/workReportUserRead/reportUserReadList`, data);
  }
  // 客户群信息
  groupchatList(data) {
    return this.http.post(`/qwGroupChat/list`, data);
  }

  // 绑定客户群信息
  updateChat(data) {
    return this.http.post(`/customer/customerInfo/updateChat`, data);
  }
  // 获取客户群信息
  groupchatGet(id) {
    return this.http.post(`/qwGroupChat/get/${id}`);
  }
  // 解绑客户群信息
  unBandChat(chatId) {
    return this.http.post(`/customer/customerInfo/unBandChat/${chatId}`);
  }
  // 公司账务信息
  companyAccountDetail(id) {
    return this.http.post(`/bsCompanyAccount/detail/${id}`);
  }
  // 保存公司账务信息
  saveBsCompanyAccount(data) {
    return this.http.post(`/bsCompanyAccount/saveBsCompanyAccount`, data);
  }
  // 申报税种列表
  taxCategory(data) {
    return this.http.post(`/taxCategory/dataList`, data);
  }
  // 月度报表种类列表
  monthReport(data) {
    return this.http.post(`/monthReport/dataList`, data);
  }
  //咨询类容列表
  consultService(data) {
    return this.http.post(`/consultService/dataList`, data);
  }
  //公司项目交付排期明细
  bsCompanyAccountStageItemList(data) {
    return this.http.post(`/bsCompanyAccountStageItem/bsCompanyAccountStageItemList`, data);
  }
  //保存公司交付排期
  saveBsCompanyAccountStage(data) {
    return this.http.post(`/bsCompanyAccountStage/saveBsCompanyAccountStage`, data);
  }

  //公司汇总服务记录
  bsCompanyAccountSumList(data) {
    return this.http.post(`/bsCompanyAccountSum/bsCompanyAccountSumList`, data);
  }
  //保存公司汇总服务记录
  saveBsCompanyAccountSum(data) {
    return this.http.post(`/bsCompanyAccountSum/saveBsCompanyAccountSum`, data);
  }

  // 分摊集团总部费用基础配置 获取金额
  groupYearGetAmount(data) {
    return this.http.post(`/groupYearConfig/getAmount`, data);
  }
  // 集团月份数据
  saveGroupMonthData(data) {
    return this.http.post(`/groupMonthData/saveGroupMonthData`, data);
  }
  // 集团月份列表
  groupMonthDataList(data) {
    return this.http.post(`/groupMonthData/groupMonthDataList`, data);
  }
  // 集团月份详情
  groupMonthDetail(id) {
    return this.http.post(`/groupMonthData/detail/${id}`);
  }
  // 集团月份 - 删除
  groupMonthDelete(id) {
    return this.http.post(`/groupMonthData/delete/${id}`);
  }
  //请款审核 删除回单
  requestAttachmentDel(id) {
    return this.http.post(`/requestPaymentAttachment/delete/${id}`);
  }
  // 请款审核  上传回单
  requestAttachmentSave(data) {
    return this.http.post(`/requestPaymentAttachment/saveAttachment`, data);
  }
  // 预收款退款
  advanceOrderrefundAmount(data) {
    return this.http.post(`/bsCustomerAdvanceOrder/refundAmount`, data);
  }


  /**
 * @description 产品分类列表
 */
  getCateList(data) {
    return this.http.post(`/bsProduct/cateList`, data);
  }
  /**
* @description 产品总数
*/
  productCount() {
    return this.http.post(`/bsProduct/productCount`);
  }
  /**
 * @description 总产品类别列表
 */
  getProductAllList(data) {
    return this.http.post(`/bsProduct/productList`, data);
  }

  //获取产品
  getProductInfo(id) {
    return this.http.post(`/bsProduct/get/${id}`);
  }
  /**
* @description 修改产品
*/
  saveOrUpdateProduct(data) {
    return this.http.post(`/bsProduct/saveOrUpdate`, data);
  }
  //删除产品
  delProductInfo(id) {
    return this.http.post(`/bsProduct/del/${id}`);
  }

  /**
  * @description 待上架产品
  */
  getProductUpList(data) {
    return this.http.post(`/bsProduct/upList`, data);
  }

  // 客户推荐
  // 我推荐的
  myRecommendList(data) {
    return this.http.post(`/bsCustomerRecommend/myRecommendList`, data);
  }
  // 我接收的
  myReceiveList(data) {
    return this.http.post(`/bsCustomerRecommend/myReceiveList`, data);
  }
  //删除推荐
  delRecommend(id) {
    return this.http.post(`/bsCustomerRecommend/del/${id}`);
  }
  // 新增推荐
  addRecommend(data) {
    return this.http.post(`/bsCustomerRecommend/add`, data);
  }
  // 我的待结算单
  mySettlementList(data) {
    return this.http.post(`/bsCustomerRecommend/mySettlementList`, data);
  }
  // 增加优惠券
  couponConfigAdd(data) {
    return this.http.post(`/bsCouponConfig/add`, data);
  }
  // 优惠券列表
  couponConfigList(data) {
    return this.http.post(`/bsCouponConfig/dataList`, data);
  }
  //删除优惠券
  delCoupon(id) {
    return this.http.post(`/bsCouponConfig/del/${id}`);
  }
  //获取优惠券详情
  getCoupon(id) {
    return this.http.post(`/bsCouponConfig/get/${id}`);
  }
  //获取签约公司 根据公司ID
  getPaymentCompanieList(officeId) {
    return this.http.get(`/sys/officeSignedEnterprise/getPaymentCompanieList/${officeId}`);
  }
  // 赠送优惠券
  getBsCouponInfo(data) {
    return this.http.post(`/bsCouponInfo/getBsCouponInfo`, data);
  }
  // 赠送列表
  customerCouponList(data) {
    return this.http.post(`/bsCouponInfo/customerCouponList`, data);
  }
  // 客户可用优惠券
  customerAvailableCouponList(data) {
    return this.http.post(`/bsCouponInfo/customerAvailableCouponList`, data);
  }
  // 增加转款
  addTransfer(data) {
    return this.http.post(`/bsCustomerRecommend/addTransfer`, data);
  }
  // 增加转款发票
  uprecommendDateAttachment(data) {
    return this.http.post(`/bsCustomerRecommend/updateAttachment`, data);
  }
  /**
 * 获取客户推荐转款审核列表
 * @param data
 */
  getRecommendList(data) {
    return this.http.post('/bsCustomerRecommend/recommendList', data);
  }
  /**
    客户推荐转款审核
    */
  transferRecommendCheck(data) {
    return this.http.post(`/bsCustomerRecommend/checkNew`, data);
  }

  //获取客户推荐转款详情
  transferRecommendDetail(id) {
    return this.http.post(`/bsCustomerRecommend/detail/${id}`);
  }
  //销售统计
  saleStatisticsList(data) {
    return this.http.post(`/sysUserSaleStatistics/dataList`, data);
  }
  //销售统计 刷新月份数据
  refreshSaleStatistics(month) {
    return this.http.post(`/sysUserSaleStatistics/refresh/${month}`);
  }

  //财务报表 刷新年份数据
  refreshOfficeYear(year) {
    return this.http.post(`/sysOfficeYear/refresh/${year}`);
  }
  //财务报表 财务分析刷新年份数据
  refreshOfficeYearFx(year) {
    return this.http.post(`/sysOfficeYear/refreshFx/${year}`);
  }

  // 工商团队统计
  //销售统计 配置
  teamStatisticsSetList(data) {
    return this.http.post(`/sysUserGsTeamStatistics/dataList`, data);
  }
  //销售统计 保存配置
  saveTeamStatistics(data) {
    return this.http.post(`/sysUserGsTeamStatistics/saveTeamStatistics`, data);
  }
  //销售统计 删除配置
  saveTeamStatisticsDel(id) {
    return this.http.post(`/sysUserGsTeamStatistics/del/${id}`);
  }
  //销售统计 配置详情
  saveTeamStatisticsDetail(id) {
    return this.http.post(`/sysUserGsTeamStatistics/detail/${id}`);
  }
  //销售统计 获取周期配置
  sysUserGsWeekConfig(data) {
    return this.http.post(`/sysUserGsWeekConfig/dataList`, data);
  }
  //汇总工单 员工数据
  teamUserStatistics(data) {
    return this.http.post(`/customer/orderTask/teamUserStatistics`, data);
  }
  //汇总工单 汇总数据
  teamSumStatisticsAll() {
    return this.http.post(`/customer/orderTask/teamSumStatistics`);
  }
  //汇总工单 查个人数据
  userOrderTaskList(data) {
    return this.http.post(`/customer/orderTask/userOrderTaskList`, data);
  }
  //库存工单
  stockOrderTask(orderTaskId) {
    return this.http.post(`/customer/orderTask/stock/${orderTaskId}`);
  }
  //取消库存工单
  cancelStock(orderTaskId) {
    return this.http.post(`/customer/orderTask/cancelStock/${orderTaskId}`);
  }
  //  刷新月份数据
  teamUserStatisticsRefresh(month) {
    return this.http.post(`/sysUserGsTeamStatistics/refresh/${month}`);
  }

  //  更改最终确认流失时间
  updateCustomerAway(data) {
    return this.http.post(`/bsCustomerAway/updateCustomerAway`, data);
  }

  // 商机 列表
  customerBusinessList(data) {
    return this.http.post(`/bsCustomerBusiness/customerBusinessList`, data);
  }
  // 我的商机 列表
  myCustomerBusinessList(data) {
    return this.http.post(`/bsCustomerBusiness/myCustomerBusinessList`, data);
  }
  // 商机 新建或修改商机
  businessAddOrUpdate(data) {
    return this.http.post(`/bsCustomerBusiness/addOrUpdate`, data);
  }
  //  删除商机
  businessDel(id) {
    return this.http.post(`/bsCustomerBusiness/del/${id}`);
  }
  //  无法成交商机
  businessUndeal(id) {
    return this.http.post(`/bsCustomerBusiness/undeal/${id}`);
  }
  //  成交商机
  businessDeal(id) {
    return this.http.post(`/bsCustomerBusiness/deal/${id}`);
  }
  //  商机详情
  businessDetail(id) {
    return this.http.post(`/bsCustomerBusiness/detail/${id}`);
  }


  // 战报
  //战报 配置
  battlesList(data) {
    return this.http.post(`/brCompany/dataList`, data);
  }
  //战报 保存配置
  battlesSave(data) {
    return this.http.post(`/brCompany/saveBrCompany`, data);
  }
  //战报 启用配置
  battlesStart(id, startFlag) {
    return this.http.post(`/brCompany/start/${id}/${startFlag}`);
  }
  //战报 删除配置
  battlesDel(id) {
    return this.http.post(`/brCompany/del/${id}`);
  }
  //战报 配置详情
  battlesDetail(id) {
    return this.http.post(`/brCompany/detail/${id}`);
  }
  //战报 排名前10
  brUserTop(brMonth) {
    return this.http.post(`/brUser/dataList/${brMonth}`);
  }
  //战报 排名前10 发送群消息
  sendTop10(brMonth) {
    return this.http.post(`/brUser/sendTop10/${brMonth}`);
  }
  //战报 公司排名
  brCompanyTop(brMonth) {
    return this.http.post(`/brCompany/dataList/${brMonth}`);
  }
  //战报 公司排名 发送群消息
  sendCompanyTop10(brMonth) {
    return this.http.post(`/brCompany/sendTop/${brMonth}`);
  }

  /**
* 工单交付 修改附件
* @param data
*
*/
  updateFinishTaskAttachment(data) {
    return this.http.post(`/customer/orderTask/updateFinishTaskAttachment`, data);
  }

  //财税顾问 配置
  teamPerformSetList(data) {
    return this.http.post(`/sysUserPerformanceTeamConfig/dataList`, data);
  }
  //财税顾问 保存配置
  saveTeamPerform(data) {
    return this.http.post(`/sysUserPerformanceTeamConfig/saveTeamConfig`, data);
  }
  //财税顾问 删除配置
  saveTeamPerformDel(id) {
    return this.http.post(`/sysUserPerformanceTeamConfig/del/${id}`);
  }
  //财税顾问 配置详情
  saveTeamPerformDetail(id) {
    return this.http.post(`/sysUserPerformanceTeamConfig/detail/${id}`);
  }

  //财税顾问 配置 年
  officeYearConfig(data) {
    return this.http.post(`/sysOfficeYearConfig/dataList`, data);
  }
  //财税顾问 保存配置 年
  saveOfficeYearConfig(data) {
    return this.http.post(`/sysOfficeYearConfig/saveOrUpdate`, data);
  }
  //财税顾问 删除配置 年
  delOfficeYearConfig(id) {
    return this.http.post(`/sysOfficeYearConfig/del/${id}`);
  }
  //财税顾问 配置详情 年
  detailOfficeYearConfig(id) {
    return this.http.post(`/sysOfficeYearConfig/detail/${id}`);
  }

  //个人目标 配置 
  performanceConfigListPerson(data) {
    return this.http.post(`/sysUserPerformanceConfig/configList`, data);
  }
  //个人目标 保存
  performanceConfigSave(data) {
    return this.http.post(`/sysUserPerformanceConfig/saveInfo`, data);
  }
  //个人目标 配置详情
  performanceConfigGet(id) {
    return this.http.post(`/sysUserPerformanceConfig/get/${id}`);
  }

  //客户详情 客户服务
  customerServerList(customerId) {
    return this.http.post(`/bsCompanyServerInfo/customerServerList/${customerId}`);
  }

  //通过产品查询可用，已用比例
  blData(orderProductId) {
    return this.http.post(`/customer/orderTask/blData/${orderProductId}`);
  }

  //根据订单产品查询工单列表
  orderTaskList(orderProductId) {
    return this.http.post(`/customer/orderTask/orderTaskList/${orderProductId}`);
  }

  //更新工单毛利占比
  updateBlVal(data) {
    return this.http.post(`/customer/orderTask/updateBlVal`, data);
  }
  //更改服务产值
  updateServicePrice(data) {
    return this.http.post(`/customer/orderProduct/updateServicePrice`, data);
  }

  //本月所有交付工单明细
  byAllDeliverList(data) {
    return this.http.post(`/bsOrderTaskStatistics/byAllDeliverList`, data);
  }
  //当月实际新增工单明细
  dyNewList(data) {
    return this.http.post(`/bsOrderTaskStatistics/dyNewList`, data);
  }
  //全部在办工单量（含库存单量）明细
  inList(data) {
    return this.http.post(`/bsOrderTaskStatistics/inList`, data);
  }

  //未开启数量明细
  unOpenCountList(data) {
    return this.http.post(`/bsOrderTaskStatistics/unOpenCountList`, data);
  }

  //未完成明细
  // 上月未完成工单量 字段：nextMonth
  // 剩余可交付工单 字段：unCompleteMonth
  uncompleteList(data) {
    return this.http.post(`/bsOrderTaskStatistics/uncompleteList`, data);
  }

  ///库存明细
  // 剩余库存工单 字段：stockMonth
  stockList(data) {
    return this.http.post(`/bsOrderTaskStatistics/stockList`, data);
  }

  // 当月完结本月工单明细
  dyCompleteList(data) {
    return this.http.post(`/bsOrderTaskStatistics/dyCompleteList`, data);
  }

  // 完结交付工单总产值
  completeDeliverValList(data) {
    return this.http.post(`/bsOrderTaskStatistics/completeDeliverValList`, data);
  }

  // 业绩毛利
  grossAmountListTask(data) {
    return this.http.post(`/bsOrderTaskStatistics/grossAmountList`, data);
  }
  // 当周未完成工单明细
  dyWeekUnCompleteList(data) {
    return this.http.post(`/bsOrderTaskStatistics/dyWeekUnCompleteList`, data);
  }
  // 销售统计明细
  // 代账客户数量
  dzCustomerList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/dzCustomerList/${userId}/${month}`);
  }

  // 代账公司数量 、每月服务产值明细
  dzCompanyList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/dzCompanyList/${userId}/${month}`);
  }

  //当月总业绩（毛利）
  grossAmountList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/grossAmountList/${userId}/${month}`);
  }

  //流失客户户数、流失客户金额
  logoutList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/logoutList/${userId}/${month}`);
  }
  //代账客户产生的新增业绩
  oldNewAddPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/oldNewAddPriceList/${userId}/${month}`);
  }
  //代账客户产生的新增代账户数
  oldNewAddAccountList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/oldNewAddAccountList/${userId}/${month}`);
  }
  //代账客户产生的转介绍业绩、代账客户转介绍产生的成交客户数
  oldZjsPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/oldZjsPriceList/${userId}/${month}`);
  }
  //代账客户产生转介绍行为的客户数、代账客户转介绍产生的客户数
  zjsActionCountList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/zjsActionCountList/${userId}/${month}`);
  }

  //代账客户（复购）新签代账户数、代账客户（复购）新签代账业绩明细
  oldNewAccountList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/oldNewAccountList/${userId}/${month}`);
  }
  //代账客户转介绍新签代账客户户数、代账客户转介绍新签代账业绩明细
  oldZjsNewAccountPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/oldZjsNewAccountPriceList/${userId}/${month}`);
  }

  //新签代账户数、新签代账业绩明细
  newAccountAddPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/newAccountAddPriceList/${userId}/${month}`);
  }
  //税务合规新签业绩明细
  swhXqPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/swhXqPriceList/${userId}/${month}`);
  }
  //工商新签业绩明细
  gsXqPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/gsXqPriceList/${userId}/${month}`);
  }
  //会计到家新签业绩明细
  kjdjXqPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/kjdjXqPriceList/${userId}/${month}`);
  }
  //当期应续代账户数、当期应续代账金额明细
  yxPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/yxPriceList/${userId}/${month}`);
  }
  //累计欠费户数、累计欠费金额明细
  qfPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/qfPriceList/${userId}/${month}`);
  }
  //代账按期已续费户数、代账按期已续费金额明细
  xfPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/xfPriceList/${userId}/${month}`);
  }
  //代账回续户数、代账回续金额明细
  hfPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/hfPriceList/${userId}/${month}`);
  }
  //代账提前续费户数、代账提前续费金额明细
  tqXfPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/tqXfPriceList/${userId}/${month}`);
  }
  //代账跨年续费户数、代账跨年续费金额明细
  jumpPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/jumpPriceList/${userId}/${month}`);
  }
  //其他续费明细
  otherRenewList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/otherRenewList/${userId}/${month}`);
  }
  //代账降价户数、代账降价金额明细
  lowPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/lowPriceList/${userId}/${month}`);
  }
  //内部注销户数、内部注销金额明细
  logOffPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/logOffPriceList/${userId}/${month}`);
  }
  //新增明细
  newAddPriceList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/newAddPriceList/${userId}/${month}`);
  }
  //滚动已续数量和金额明细
  rollRenewedList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/rollRenewedList/${userId}/${month}`);
  }
  //滚动应续数量和金额明细
  rollRenewList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/rollRenewList/${userId}/${month}`);
  }
  //户均人效值
  rxList(saleStatisticsId) {
    return this.http.post(`/bsCompanyServerFwz/rxList/${saleStatisticsId}`);
  }

  //非代账客户数量
  unAccountCustomerList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/unAccountCustomerList/${userId}/${month}`);
  }

  //非代账公司数量
  unAccountCompanyList(userId, month) {
    return this.http.post(`/bsCompanyServerFwz/unAccountCompanyList/${userId}/${month}`);
  }

  // 销售新增统计列表
  saleNewStatistics(data) {
    return this.http.post(`/sysUserSaleNewStatistics/dataList`, data);
  }

  //销售新增  新增明细
  saleNewAddPriceList(userId, month, serverTye) {
    return this.http.post(`/sysUserSaleNewStatistics/newAddPriceList/${userId}/${month}/${serverTye}`);
  }

  //销售新增  代账客户转介绍新签代账客户户数、代账客户转介绍新签代账业绩
  djZjsNewPriceList(userId, month, serverTye) {
    return this.http.post(`/sysUserSaleNewStatistics/djZjsNewPriceList/${userId}/${month}/${serverTye}`);
  }


  //销售新增  非代账客户转介绍新签代账客户户数、非代账客户转介绍新签代账业绩
  unDjZjsNewPriceList(userId, month, serverTye) {
    return this.http.post(`/sysUserSaleNewStatistics/unDjZjsNewPriceList/${userId}/${month}/${serverTye}`);
  }

  //销售新增  代账客户（复购）新签代账户数
  djNewList(userId, month, serverTye) {
    return this.http.post(`/sysUserSaleNewStatistics/djNewList/${userId}/${month}/${serverTye}`);
  }

  //销售新增  非代账客户（复购）新签代账户数、非代账客户（复购）新签代账业绩
  unOldNewAccountList(userId, month, serverTye) {
    return this.http.post(`/sysUserSaleNewStatistics/unOldNewAccountList/${userId}/${month}/${serverTye}`);
  }

  //销售新增  自主开发新签代账户数、自主开发新签代账业绩
  zzkfNewList(userId, month, serverTye) {
    return this.http.post(`/sysUserSaleNewStatistics/zzkfNewList/${userId}/${month}/${serverTye}`);
  }

  //销售新增  内部介绍新签代账户数、内部介绍新签代账业绩
  nbjsNewList(userId, month, serverTye) {
    return this.http.post(`/sysUserSaleNewStatistics/nbjsNewList/${userId}/${month}/${serverTye}`);
  }

  //销售新增 渠道开发新签代账户数、渠道开发新签代账户数
  dkfNewList(userId, month, serverTye) {
    return this.http.post(`/sysUserSaleNewStatistics/dkfNewList/${userId}/${month}/${serverTye}`);
  }
  //每月数据统计
  grossAmountDayList(userId, month) {
    return this.http.post(`/dailyData/grossAmountDayList/${userId}/${month}`);
  }
  //每月数据统计 总收款金额
  grossAmountMonthList(userId, month) {
    return this.http.post(`/dailyData/grossAmountMonthList/${userId}/${month}`);
  }

  //我接收的 工单汇总
  orderTaskStatistics() {
    return this.http.post(`/customer/orderTask/orderTaskStatistics`);
  }

  //更改产品公司
  updateProductCompanyId(data) {
    return this.http.post(`/customer/orderProduct/updateProductCompanyId`, data);
  }

  //根据客户查询默认发票信息
  getDefaultByCustomerId(customerId) {
    return this.http.post(`/bsCustomerInvoice/getDefaultByCustomerId/${customerId}`);
  }

  //根据客户查询发票信息
  getByCustomerId(customerId) {
    return this.http.post(`/bsCustomerInvoice/getByCustomerId/${customerId}`);
  }

  //根据客户设置默认发票信息
  setDefault(id, customerId) {
    return this.http.post(`/bsCustomerInvoice/setDefault/${id}/${customerId}`);
  }

  //删除
  bsCustomerInvoiceDel(id) {
    return this.http.post(`/bsCustomerInvoice/del/${id}`);
  }

  //客户关系用户列表数据
  relationUserList(data) {
    return this.http.post(`/bsRelationUser/dataList`, data);
  }
  //新增渠道
  relationUserAdd(data) {
    return this.http.post(`/bsRelationUser/add`, data);
  }
  //删除渠道
  relationUserDel(id) {
    return this.http.post(`/bsRelationUser/del/${id}`);
  }
  //审核通过
  relationUsePass(id) {
    return this.http.post(`/bsRelationUser/pass/${id}`);
  }
  //审核不通过
  relationUserUnpass(id) {
    return this.http.post(`/bsRelationUser/unpass/${id}`);
  }
  //客户列表
  companyRelationLst(data) {
    return this.http.post(`/bsCompanyRelation/dataList`, data);
  }

  //每日回款数据
  dailyDataList(data) {
    return this.http.post(`/dailyData/dataList`, data);
  }


  //每日回款数据 合计
  dailyDataListSum(data) {
    return this.http.post(`/dailyData/dataListSum`, data);
  }


  // 新增提成配置 列表
  commissionConfigList(data) {
    return this.http.post(`/newCommissionConfig/dataList`, data);
  }
  // 新增提成配置 保存
  commissionConfigSave(data) {
    return this.http.post(`/newCommissionConfig/save`, data);
  }
  // 新增提成配置 修改
  commissionConfigUpdate(data) {
    return this.http.post(`/newCommissionConfig/update`, data);
  }
  // 新增提成配置 详情
  commissionConfigDetail(id) {
    return this.http.post(`/newCommissionConfig/get/${id}`);
  }
  // 新增提成配置 删除
  commissionConfigDel(id) {
    return this.http.post(`/newCommissionConfig/del/${id}`);
  }
  // 工商人员按周统计
  weekStatisticsDataList(data) {
    return this.http.post(`/sysUserGsWeekStatistics/dataList`, data);
  }
  // 工商部门按周统计
  weekStatisticsDeptDataList(data) {
    return this.http.post(`/sysUserGsWeekStatistics/deptDataList`, data);
  }
  //批量导入 续费管理
  serverInfoImport(data) {
    return this.http.upload(data, `/bsCompanyServerInfo/import`);
  }
  // 查看导入结果 续费管理
  serverInfoResult(data) {
    return this.http.post(`/bsCompanyServerInfoEx/checkResult`, data);
  }
  // 查看导入列表 续费管理
  serverInfoExList(data) {
    return this.http.post(`/bsCompanyServerInfoEx/exList`, data);
  }

  // 回款流水 导入功能 start
  // 订单审核列表 导入列表
  getPayVerifyListImport(data) {
    return this.http.post(`/bsBankStatement/dataList`, data);
  }

  // 订单审核列表 删除
  getPayVerifyListDel(id) {
    return this.http.post(`/bsBankStatement/del/${id}`);
  }

  //订单审核列表 导入接口
  bsBankStatementImport(data, bankType) {
    return this.http.upload(data, `/bsBankStatement/import/${bankType}`);
  }

  // 订单审核列表 单个匹配
  mateDataSingle(id) {
    return this.http.post(`/bsBankStatement/mateData/${id}`);
  }

  // 手动绑定订单 
  bindOrder(data) {
    return this.http.post(`/bsBankStatement/bindOrder`, data);
  }
  // 订单列表
  bindOrderSearch(orderNo) {
    return this.http.post(`/bsBankStatement/order/list/${orderNo}`);
  }

  // 过期设置
  bsBankStatement(data) {
    return this.http.post(`/bsBankStatement/expire`, data);
  }

  // 银行账号信息 列表
  bankInfoList(data) {
    return this.http.post(`/bsBankInfo/dataList`, data);
  }
  // 银行账号信息 删除
  bankInfoDel(id) {
    return this.http.post(`/bsBankInfo/del/${id}`);
  }
  // 银行账号信息 查询收款公司
  getByBankType(bankType) {
    return this.http.post(`/bsBankInfo/getByBankType/${bankType}`);
  }
  // 银行账号信息 保存或修改
  bankInfoSave(data) {
    return this.http.post(`/bsBankInfo/saveOrUpdate`, data);
  }
  // 回款流水 导入功能 end
  //  请款批量打印
  printList(data) {
    return this.http.post(`/sys/request/payment/printList`, data);
  }
  //  退款批量打印
  refundsPrintList(data) {
    return this.http.post(`/sys/orderRefunds/printList`, data);
  }
  // 报销批量打印
  applyPrintList(data) {
    return this.http.post(`/sysUserApply/printList`, data);
  }
  //  核销批量打印
  verificationPrintList(data) {
    return this.http.post(`/sysUserApplyVerification/dataList`, data);
  }

  // 预算管理
  // 预算管理 - 列表数据
  budgetConfigList(data) {
    return this.http.post(`/budgetConfig/dataList`, data);
  }
  // 我的预算 - 列表数据
  budgetConfigMyList(data) {
    return this.http.post(`/budgetConfig/myDataList`, data);
  }
  // 预算管理 - 保存数据
  budgetConfigSave(data) {
    return this.http.post(`/budgetConfig/save`, data);
  }
  // 预算管理 - 详情
  budgetConfigDetail(id) {
    return this.http.post(`/budgetConfig/detail/${id}`);
  }
  // 预算管理 - 删除
  budgetConfigDel(id) {
    return this.http.post(`/budgetConfig/del/${id}`);
  }
  /**
  * 预算管理 - 获取报销明细
  * @param nominalCate 报销名目列表数据(名目分类0人员薪酬1其他费用)
  *
  */
  applyNominalList(nominalCate) {
    return this.http.get(`/sysUserApplyNominal/listData/${nominalCate}`);
  }
  // 预算管理 - 根据公司或部门获取当月配置
  getConfigBudget() {
    return this.http.post(`/budgetConfig/getConfig`);
  }
  // 预算管理 - 获取当月配置表
  getConfigBudgetAll(month) {
    let url = '/budgetConfig/getConfigList';
    if (month) {
      url = `/budgetConfig/getConfigList/${month}`;
    }
    return this.http.post(url);
  }
  // 预算管理 - 报销是否需要预算配置
  getNeedConfigBudget(id) {
    return this.http.post(`/sys/office/get/${id}`);
  }
  // 预算管理 - 预算使用明细
  budgetDetail(data) {
    return this.http.post(`/sysUserApplyItem/budgetDetail`, data);
  }
  // 预算管理 - 预算审核
  budgetCheckNew(data) {
    return this.http.post(`/budgetConfig/checkNew`, data);
  }
  // 项目管理 - 列表数据
  budgetProjectList(data) {
    return this.http.post(`/budgetProject/dataList`, data);
  }
  // 项目管理 - 保存
  budgetProjectSave(data) {
    return this.http.post(`/budgetProject/save`, data);
  }
  // 项目管理 - 删除
  budgetProjectDel(id) {
    return this.http.post(`/budgetProject/del/${id}`);
  }
  // 预算管理 - 详情
  budgetProjectDetail(id) {
    return this.http.post(`/budgetProject/detail/${id}`);
  }
  // 预算管理 - 启用或关闭
  budgetProjectStatus(id, budgetStatus) {
    return this.http.post(`/budgetProject/status/${id}/${budgetStatus}`);
  }
  // 预算管理 - 项目使用明细
  budgetProjectUserApply(budgetProjectId) {
    return this.http.post(`/sysUserApply/useList/${budgetProjectId}`);
  }
  // 预算管理 - 项目使用明细
  budgetProjectUseDataList(data?) {
    return this.http.post(`/budgetProject/useDataList`, data);
  }

  // 预算管理 - 我参与的项目明细
  budgetProjectMyInvolved(data?) {
    return this.http.post(`/budgetProject/myInvolved`, data);
  }

  // 数据报表新 start
  // 获取ROI数据
  newDashCompany(data) {
    return this.http.post(`/customer/bsCompany/companyList2`, data);
  }
  // 注册资本
  newDashZczbList(data) {
    return this.http.post(`/customer/bsCompany/zczbList`, data);
  }
  // 注册资本
  newDashStatusList(data) {
    return this.http.post(`/customer/bsCompany/statusList`, data);
  }
  // 注册年
  newDashYearList(data) {
    return this.http.post(`/customer/bsCompany/yearList`, data);
  }
  // 公司数量
  newDashCompanyCount(data) {
    return this.http.post(`/customer/bsCompany/companyCount`, data);
  }
  // 省份列表
  newDashCompanyProvince(data) {
    return this.http.post(`/customer/bsCompany/provinceList`, data);
  }
  // 数据报表新 end

  // 文章模块
  // 文章模块 - 我的文章列表
  taxArticleList(data) {
    return this.http.post(`/taxArticle/taxArticleList`, data);
  }
  // 文章模块 - 全部文章列表
  taxArticleAll(data) {
    return this.http.post(`/taxArticle/taxArticleAllList`, data);
  }
  // 文章模块 - 保存
  saveOrUpdateArticle(data) {
    return this.http.post(`/taxArticle/saveOrUpdate`, data);
  }
  // 文章模块 - 状态
  // 状态0草稿1已发布2关闭3关闭分享
  articleStatus(id, status) {
    return this.http.post(`/taxArticle/shareTaxArticle/${id}/${status}`);
  }
  // 文章模块 - 删除
  articleDel(id) {
    return this.http.post(`/taxArticle/del/${id}`);
  }
  // 文章模块 - 详情
  articleDetail(id) {
    return this.http.post(`/taxArticle/detail/${id}`);
  }
  // 文章模块 - 文章阅读记录列表
  taxArticleReadList(data) {
    return this.http.post(`/taxArticleRead/taxArticleReadList`, data);
  }

  // 文章模块 - 用户分享文章列表
  taxShareArticleList(data) {
    return this.http.post(`/taxUserShareArticle/taxShareArticleList`, data);
  }

  // 文章模块 - 保存分享的文章
  saveTaxShareArticleList(data) {
    return this.http.post(`/taxUserShareArticle/saveInfo`, data);
  }

  // 财务报表配置
  // 公司2023年报基础表
  yearBase(data) {
    return this.http.post(`/sysOffice2023YearBase/list`, data);
  }

  // 公式 批量保存和修改
  saveBatchOrUpdate(data) {
    return this.http.post(`/sysOfficeYearFormula/saveBatchOrUpdate`, data);
  }

  // 公式  列表
  sysOfficeYearFormula(data) {
    return this.http.post(`/sysOfficeYearFormula/list`, data);
  }
  // 公式  详情
  yearFormulaDetail(id) {
    return this.http.post(`/sysOfficeYearFormula/detail/${id}`);
  }

  // 公式 - 删除
  yearFormulaDel(id) {
    return this.http.post(`/sysOfficeYearFormula/del/${id}`);
  }

  /**
*财务预算报表 - 公司年报 多个公司
* @param data
*
*/
  getBudgetOfficeYearListAll(data) {
    return this.http.post(`/budgetOfficeYear/listAll`, data);
  }

  // //财务预算报表 刷新年份数据
  refreshBudgetOfficeYear(year) {
    return this.http.post(`/budgetOfficeYear/refresh/${year}`);
  }

  //预算管理 刷新年份数据
  refreshBudgetConfigYear(year) {
    return this.http.post(`/budgetConfig/refresh/${year}`);
  }

  /**
*统计产品类目 列表数据
* @param data
*
*/
  statisticsProductList(data) {
    return this.http.post(`/statisticsProduct/dataList`, data);
  }

  /**
*统计产品类目 列表数据汇总

* @param data
*
*/
  statisticsProductListSum(data) {
    return this.http.post(`/statisticsProduct/dataSum`, data);
  }

  //统计产品类目 刷新年份数据
  refreshStatisticsProduct(year) {
    return this.http.post(`/statisticsProduct/refresh/${year}`);
  }
  // 代账收费情况start
  companyYearAmount(companyId) {
    return this.http.post(`/customer/bsCompany/yearAmount/${companyId}`);
  }
  // 代账收费情况end

  // 基本信息start
  // 法人关联企业
  // 查询
  operEnterprise(companyId) {
    return this.http.post(`/bsCompanyOperEnterprise/findByCompanyId/${companyId}`);
  }
  // 增加
  addOperEnterprise(data) {
    return this.http.post(`/bsCompanyOperEnterprise/addInfo`, data);
  }
  // 同步
  getRelevanceCompanyInfo(data, companyId) {
    return this.http.post(`/orgCompany/getRelevanceCompanyInfo/${companyId}`, data);
  }
  // 删除
  delOperEnterprise(id) {
    return this.http.post(`/bsCompanyOperEnterprise/delete/${id}`);
  }
  // 详情
  operEnterpriseDetail(id) {
    return this.http.post(`/bsCompanyOperEnterprise/detail/${id}`);
  }

  // 基本信息end

  // 行业经营特征 start
  // 增加
  industryAspectAdd(data) {
    return this.http.post(`/bsCompanyIndustryAspect/add`, data);
  }
  // 查看
  industryAspectDetail(companyId) {
    return this.http.post(`/bsCompanyIndustryAspect/findByCompanyId/${companyId}`);
  }
  // 行业经营特征 end
  // 联系人 start
  // 增加
  decisionMakerAdd(data) {
    return this.http.post(`/bsCompanyDecisionMaker/add`, data);
  }
  // 编辑
  decisionMakerEdit(data) {
    return this.http.post(`/bsCompanyDecisionMaker/update`, data);
  }
  // 删除
  decisionMakerDel(id) {
    return this.http.post(`/bsCompanyDecisionMaker/delete/${id}`);
  }
  // 列表
  decisionMakerList(companyId) {
    return this.http.post(`/bsCompanyDecisionMaker/findByCompanyId/${companyId}`);
  }
  // 详情
  decisionMakerDetail(id) {
    return this.http.post(`/bsCompanyDecisionMaker/detail/${id}`);
  }
  // 联系人 end
  // 财税档案 start
  // 增加
  companyTaxAdd(data) {
    return this.http.post(`/bsCompanyTax/addInfo`, data);
  }
  // 查看
  companyTaxDetail(companyId) {
    return this.http.post(`/bsCompanyTax/findByCompanyId//${companyId}`);
  }
  // 历史记录及周期
  companyHandoverList(data) {
    return this.http.post(`/bsCompanyHandover/companyHandoverList`, data);
  }
  // 历史记录及周期 新
  companyHandoverLists(data) {
    return this.http.post(`/bsCompanyAccountRecord/companyHandoverList`, data);
  }
  // 财税档案 end
  // 企服清单 start
  // 列表
  companyListServer(companyId) {
    return this.http.post(`/bsCompanyList/findByCompanyId/${companyId}`);
  }
  // 购买的产品明细
  companyBuyProduct(companyId) {
    return this.http.post(`/customer/orderProduct/findServerByCompanyId/${companyId}`);
  }
  // 修改企服清单
  addCompanyListServer(data) {
    return this.http.post(`/bsCompanyList/addInfo`, data);
  }
  // 修改跟进策略
  addCompamyLog(data) {
    return this.http.post(`/bsCompanyListLog/addInfo`, data);
  }
  // 删除跟进策略
  delCompamyLog(companyId) {
    return this.http.post(`/bsCompanyListLog/delete/${companyId}`);
  }
  // 企服清单 end

  // 代账相关信息
  accountInfo(companyId) {
    return this.http.post(`/customer/bsCompany/accountInfo/${companyId}`);
  }


  // 下户信息
  // 下户记录
  companyOutInfoList(data) {
    return this.http.post(`/outInfo/companyOutInfoList`, data);
  }
  // 保存拜访见客
  saveOutInfo(data) {
    return this.http.post(`/outInfo/saveOutInfo`, data);
  }
  // 详情
  ountInfoDetail(id) {
    return this.http.post(`/outInfo/detail/${id}`);
  }
  // 基础问题
  outInfoQuestion(data) {
    return this.http.post(`/outInfoQuestion/listData`, data);
  }


  // 满意度回访 start
  satisfactionList(companyId) {
    return this.http.post(`/bsCompanySatisfaction/findByCompanyId/${companyId}`);
  }
  // 保存
  addSatisfaction(data) {
    return this.http.post(`/bsCompanySatisfaction/addInfo`, data);
  }
  // 我的客户回访 列表
  mySatisfactionList(data) {
    return this.http.post(`/bsCompanySatisfaction/mySatisfactionList`, data);
  }
  // 团队客户回访 列表
  teamSatisfactionList(data) {
    return this.http.post(`/bsCompanySatisfaction/satisfactionList`, data);
  }
  // 团队客户回访 列表
  getSatisfactionUrl(data) {
    return this.http.post(`/bsCompanySatisfaction/getSatisfactionUrl`, data);
  }
  // 满意度回访 end
  // 待结算产品
  settlementList(data) {
    return this.http.post(`/sysUserSettlementItemBusiness/settlementList`, data);
  }
  // 待结算退款(上月)
  refundList(data) {
    return this.http.post(`/sysUserSettlementItemRefund//refundList`, data);
  }
  // 新增提成点数据列表
  getAddprofitFee(data) {
    return this.http.post(`/newCommissionConfig/listAll`, data);
  }
  // 新增提成点数据列表
  getRewprofitFee(amount) {
    return this.http.post(`/newCommissionConfig/findXfConfig`);
  }
  // 待结算产品 (总)
  settlementListPage(data) {
    return this.http.post(`/sysUserSettlementItemBusiness/settlementListPage`, data);
  }
  // 会计交接
  // 我接收的交接列表
  receiveHandoverList(data) {
    return this.http.post(`/bsCompanyHandover/receiveHandoverList`, data);
  }
  // 我发起的交接列表
  sendHandoverList(data) {
    return this.http.post(`/bsCompanyHandover/sendHandoverList`, data);
  }
  // 交接明细
  companyHandoverDetail(id) {
    return this.http.post(`/bsCompanyHandover/detail/${id}`);
  }
  // 确认交接
  confirmHandover(id) {
    return this.http.post(`/bsCompanyHandover/confirmHandover/${id}`);
  }
  // 商机统计列表
  businessStatistics(data) {
    return this.http.post(`/bsCustomerBusinessStatistics/dataList`, data);
  }
  //刷新
  businessStatisticsRefresh() {
    return this.http.post(`/bsCustomerBusinessStatistics/refresh`);
  }
  // 来源商机明细列表
  businessStatisticsSourceList(data) {
    return this.http.post(`/bsCustomerBusinessStatistics/sourceList`, data);
  }
  // 遗留商机明细列表
  businessStatisticsYlList(data) {
    return this.http.post(`/bsCustomerBusinessStatistics/ylList`, data);
  }
  // 转单的支付详情
  getPayRecordList(orderNo) {
    return this.http.post(`/customer/payRecord/getPayRecordList/${orderNo}`);
  }
}




